import React, { useEffect } from "react";
import MutualfundPage from "../compnents/MutualfundPage";

function ServicesPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <MutualfundPage />
    </div>
  );
}

export default ServicesPage;
