import React from "react";
import Growth from "../images/growth.png";
import Hands from "../images/hands.png";
import Question from "../images/Group.jpg";

function Footer1() {
  return (
    <div className="footer1-container">
      <h4 style={{ textAlign: "center", fontSize: "30px" }}>Why choose us?</h4>
      <div className="footer1-card-holder">
        <div className="footer1-card" data-aos="zoom-in">
          <div>
            <img src={Growth} alt="" className="footer1-img" loading="lazy" />
          </div>
          <div>
            {" "}
            <h4>OUR GROWTH AND RATINGS</h4>
            <p>
              In the past four years, we have expanded by leaps and bounds to
              rank among the top fund managers in the industry. We are rated “A”
              by DataPro with a stable outlook and an upward trend.
            </p>
          </div>
        </div>
        <div className="footer1-question-img" data-aos="zoom-in">
        <img src={Question}  alt="#" style={{width:"100%"}} loading="lazy"/>

        </div>
        <div className="footer1-card" data-aos="zoom-in">
          <div>
            <img src={Hands} alt="" className="footer1-img"  loading="lazy"/>
          </div>
          <div>
            {" "}
            <h4>OUR CLIENTS AND PARTNERS</h4>
            <p>
              Over the years, TrustBanc Asset Management has formed significant
              partnerships in the financial and non-financial sectors,
              significantly increasing its asset under management and putting it
              on track to rank among the top in the industry.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer1;
