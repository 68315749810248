import React from "react";
import Container from "react-bootstrap/Container";
import Team from "../images/team.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Profile2 from "../images/picture.png";
import Profile3 from "../images/Rectangle 52.png";
import Profile7 from "../images/MD-profile.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavLink2 from "../compnents/NavLink2";
import Footer5 from "../compnents/Footer5";
import Footer4 from "../compnents/Footer4";
// Functions for modal

function MyVerticallyCenteredModal6(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ display: "flex", padding: "30px" }}>
        <div className="profile-box">
          <img
            src={Profile2}
            alt=""
            className="profile-img"
            style={{ marginBottom: "20px" }}
          />
          <h4>Azeez Lawal</h4>
          <p>Managing Director​</p>
        </div>
        <p style={{ marginLeft: "30px" }}>
          Azeez has over 14 years’ experience across multiple roles within the
          Capital Market - Investment Banking, Financial Reporting, Strategic
          Business & Financial Planning, Business Strategy Development &
          Execution. Before joining TrustBanc, he was Chief Financial Officer,
          Head Corporate Finance and Investment Banking at Nigerian Stockbrokers
          Limited/NSL Capital Partners. He also served as the Managing Director
          and Chief Financial Officer at Constant Capital Markets & Securities
          Limited. He was Head, Capital Markets and Research at Capital Bancorp
          Plc. Azeez was part of the Corporate Finance Team that raised N40
          billion to recapitalize Wema Bank. He was on the Committee of SEC and
          NGX for the harmonization of quarterly reporting template of the two
          regulators. He is an alumnus of Delta State University, where he
          graduated as the best student in his department to obtain a bachelor’s
          degree in Accounting and Finance. He also holds a master’s degree in
          Finance from University of Lagos. Azeez is an Associate of the
          Institute of Chartered Accountants of Nigeria (ICAN) and the Chartered
          Institute of Stockbrokers (CIS). He also holds a Diploma in IFRS from
          ACCA. He joined TrustBanc in 2020 as Group Chief Financial Officer.
        </p>
      </Modal.Body>
    </Modal>
  );
}
function MyVerticallyCenteredModal7(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ display: "flex", padding: "30px" }}>
        <div className="profile-box">
          <img
            src={Profile3}
            alt=""
            className="profile-img"
            style={{ marginBottom: "20px" }}
          />
          <h4>Joy Nwaokoro</h4>
          <p>Head, Sales</p>
        </div>
        <p style={{ marginLeft: "30px" }}>
          A versatile professional with over 12 years’ experience in Business
          Analysis, Asset Management, Financial Planning, Relationship
          Management, Strategy and Customer Experience. She has successfully
          managed institutional and retail clients, having executed several
          landmark transactions. Joy has worked in various organization such as
          Virgin Nigeria Airways, ARM Investment Managers, and Cititrust Asset
          Management where she was Head, Institutional Sales and Wealth
          Management. She holds a bachelor’s degree in Computer Science from
          Ambrose Alli University and an MBA degree from the University of South
          Wales. Joy is a certified wealth management professional and financial
          planner, she joined TrustBanc in 2022.
        </p>
      </Modal.Body>
    </Modal>
  );
}
function MyVerticallyCenteredModal8(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ display: "flex", padding: "30px" }}>
        <div className="profile-box">
          <img
            src={Profile7}
            alt=""
            className="profile-img"
            style={{ marginBottom: "20px" }}
          />
          <h4>Rukayat Mutiu</h4>
          <p>Compliance Officer</p>
        </div>
        <p style={{ marginLeft: "30px" }}>
        
Rukayat has over 9 years of experience in capital Market which spans across Portfolio Management, Operations, Finance and Compliance. She started her career with Associated Discount House now Coronation Merchant Bank where she served in various capacity in the Bank and Capital Market Subsidiaries within the Group.
She joined Gresham Asset Management in 2019, where she oversees the Compliance, Operations and Finance functions of the business. She played a vital role in automation of the business operational processes, collaboration with relevant stakeholders on process and application improvement.
She is a graduate of Accounting from University of Ilorin and Associate member of the Institute of Chartered Accountant of Nigeria. Rukayat Joined TrustBanc as Operations and Compliance specialist in 2022.

        </p>
      </Modal.Body>
    </Modal>
  );
}


function TeamPage() {
  const [modalShow6, setModalShow6] = React.useState(false);
  const [modalShow7, setModalShow7] = React.useState(false);
  const [modalShow8, setModalShow8] = React.useState(false);

  return (
    <div className="team-page">
      <div
        className="main-MF"
        style={{
          backgroundImage: `url(${Team})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "150px",
          textAlign: "center",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)"
        }}
      >
        <div>
          <NavLink2 />
          <h1>Our Team</h1>
        </div>
      </div>
      {/* <div className="team-page-card1">
        <button className="team-page-btn1">
          <Link to="/ourteam" className="team-links">
            Management Team
          </Link>
        </button>
        <button className="team-page-btn2">
          <Link to="/advisorycommitee" className="team-links2">
            Advisory Committee of Experts
          </Link>
        </button>
      </div> */}
      <div className="team-page-card2">
        <h2
          style={{
            textAlign: "",
            color: "#044188",
            lineHeight: "59px"
          }}
        >
          Management Team
        </h2>
        <Container style={{ width: "60%" }} className="mx-auto p-2">
          <Row className="mt-2 mx-auto p-2">
            <Col className="p-0 m-0">
              <div className="profile-box">
                <img src={Profile2} alt="" className="profile-img" />
                <Button variant="link" onClick={() => setModalShow6(true)}>
                  <h4>Azeez Lawal</h4>
                </Button>
                <MyVerticallyCenteredModal6
                  show={modalShow6}
                  onHide={() => setModalShow6(false)}
                />
                <p>Managing Director​</p>
              </div>
            </Col>
            <Col className="p-0 m-0">
              <div className="profile-box">
                <img src={Profile7} alt="" className="profile-img" />
                <Button variant="link" onClick={() => setModalShow8(true)}>
                  {" "}
                  <h4>Rukayat Mutiu</h4> 
                </Button>{" "}
                <MyVerticallyCenteredModal8
                  show={modalShow8}
                  onHide={() => setModalShow8(false)}
                />
                <p>Compliance Officer</p>
              </div>
            </Col>
            <Col className="p-0 m-0">
              <div className="profile-box">
                <img src={Profile3} alt="" className="profile-img" />
                <Button variant="link" onClick={() => setModalShow7(true)}>
                  <h4>Joy Nwaokoro</h4>
                </Button>
                <MyVerticallyCenteredModal7
                  show={modalShow7}
                  onHide={() => setModalShow7(false)}
                />
                <p>Head, Sales</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Footer5 />
      </div>
      <div className="footer-4">
        <Footer4 />
      </div>
    </div>
  );
}

export default TeamPage;
