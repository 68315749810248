import React, { useState } from "react";
import NavLink from "../compnents/NavLink";
import "../Request.css";
import Statement from "../compnents/Statement";
import Payment from "../compnents/Payment";
import PaymentForm from "./PaymentForm";
import StatementForm from "./StatementForm";

const Request = () => {
  // condition to show/hide forms
  const [showDiv, setShowDiv] = useState(true);
  const [showDiv2, setShowDiv2] = useState(true);
  //

  return (
    <div>
      <div>
        <NavLink />
      </div>
      <div className="request-container">
        {showDiv && <Payment setShowDiv={setShowDiv} />}
        {!showDiv && <PaymentForm setShowDiv={setShowDiv} />}
        {showDiv2 && <Statement setShowDiv2={setShowDiv2} />}
        {!showDiv2 && <StatementForm setShowDiv2={setShowDiv2} />}
      </div>
    </div>
  );
};

export default Request;
