import React from "react";
import NavLink2 from "../compnents/NavLink2";
import Pin from "../images/Group337.png";
import { Link } from "react-router-dom";
import Image48 from "../images/image 48.png";
import Maskgroup4 from "../images/Mask group4.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image90 from "../images/image 90.png";
import Image91 from "../images/image 91.png";
import Image49 from "../images/image 49.png";
import Footer5 from "../compnents/Footer5";
import Image50 from "../images/image 50.png";
import TrustbancKids from "../images/TrustBanc Kids.png";
import Image51 from "../images/image 51.png";
import Footer4 from "../compnents/Footer4";

export const DollarProduct = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${Pin})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "400px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div>
          <NavLink2 />
        </div>
        <div
          style={{
            textAlign: "center",
            color: "white",
            position: "relative",
            top: "100px",
          }}
        >
          <h3
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "50px",
              lineHeight: " 75px",
              color: " white",
            }}
          >
            Wealth Management
          </h3>
        </div>
      </div>
      <div className="mutual-cards">
        {" "}
        <h4>TrustBanc Euroinvest Dollar Product</h4>
        <div className="mutual-card1">
          <img src={Image49} alt="" className="mutual-img" />
          <div className="mutual-info">
            <p>
              The TrustBanc Euroinvest is a dollar-based product that seeks to
              enable new and existing clients to take advantage of the
              opportunities in the global financial market, with an avenue to
              invest in Naira and earn in Dollars. This product is designed to
              provide currency diversification, wealth preservation against
              devaluation, and stable growth in USD.
              <br />
              <ul>
            <b>   Features</b> 
                <br />
                <li>Attractive yield, subject to market conditions.</li>
                <li>The minimum holding period is 180 days.</li>
                <li>
                  Minimum investible amount of $1000 or its Naira equivalent at
                  the prevailing market rate.
                </li>
                <li>Bi-Annual Interest Payment.</li>
                <li>
                  Option to roll over Principal and Interest at the end of the
                  Investment period.
                </li>
                <li>
                  Investment certificate will be available for all invested
                  funds.
                </li>
              </ul>
              <ul>
              <b> Benefits</b>
                <br />
                <li>Capital appreciation and preservation</li>
                <li>Competitive returns</li>
                <li>Tax-free rate of return. </li>
                <li>Diversified portfolio from local currency</li>
                <li>hedge against the risk of local currency devaluation</li>
              </ul>
            </p>
            <Link to="/contact" className="contact-link">
              Contact Us
            </Link>
          </div>
        </div>
      </div>{" "}
      <Container className="service-grid">
        <Row
          className="justify-content-md-center"data-aos="zoom-in-up"
          style={{ marginTop: "100px" }}
        >
          <h3 style={{ fontSize: "20px", marginLeft: "170px", color: "black" }}>
            OUR PRODUCTS
          </h3>
          <Col className="service-cards" md="auto">
            <h1 style={{}}>TrustBanc Eurobond Margin Loan</h1>
            <div className="service-card1">
              <img src={Image51} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  The TrustBanc Eurobond Margin Loan is a USD-dominant product
                  designed to allow interested clients who do not have the
                  $200,000 minimum investment amount at once.....
                  <button className="service-btn">
                    <Link to="/product6" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>
          <Col className="service-cards" md="auto">
            <h1 style={{}}>TrustBanc Asset Management Note (Backend)</h1>
            <div className="service-card1">
              <img src={Image90} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  This product is designed to allow both individual and
                  Institutional investors have access to an investment that
                  maximize investors returns and ensure capital preservation.
                  <button className="service-btn">
                    <Link to="/product2" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>
         
        </Row>
        <Row
          className="justify-content-md-center"data-aos="zoom-in-up"
          style={{ marginBottom: "100px", marginTop: "100px" }}
        >
         <Col className="service-cards" md="auto">
            <h1 style={{}}>TrustBanc Fixed Income Portfolio</h1>
            <div className="service-card1">
              <img src={Image48} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  The product seeks to return premium yield to its investors.
                  The authorized investment classes in the product include
                  naira-denominated insured credit instruments.....
                  <button className="service-btn">
                    <Link to="/product1" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>
          <Col className="service-cards" md="auto">
            <h1 style={{}}>TrustBanc Asset Management Note (Upfront)</h1>
            <div className="service-card1">
              <img src={Image91} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  A flexible investment focused on maximizing returns without
                  compromising risk.The product allows clients have access to a
                  secure investment that guarantees .......
                  <button className="service-btn">
                    <Link to="/product3" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row
          className=""data-aos="zoom-in-up"
          style={{
            marginBottom: "100px",
            marginTop: "100px",
            display: "flex",
           marginLeft:"35px",
            justifyContent: "space-between",
          }}
        >
          <Col className="service-cards" md="auto">
            <h1 style={{}}>
              {" "}
              <img src={TrustbancKids} alt="" style={{ width: "400px" }} />
            </h1>
            <div className="service-card1">
              <img src={Image50} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  There is no compromise for parents when it comes to securing
                  their child’s future. That is why TrustBanc Kids Save is
                  designed to help parents have access to an investment......
                  <button className="service-btn">
                    <Link to="/product5" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>
         <Col className="service-cards" md="auto"></Col>
        </Row>
      </Container>
      <div>
        <Footer5 />
      </div>
      <div className="footer-4">
      <Footer4 /></div>
    </div>
  );
};
