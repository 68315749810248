import React from "react";
import Medal from "../images/medal.png";

function Footer2() {
  return (
    <div className="footer2">
      <div className="footer2-cards">
        <div className="footer2-card1">
          <form
            action="https://formsubmit.co/support@trustbancgroup.com"
            method="POST"
          >
            <input
              type="hidden"
              name="_next"
              value="
              https://trustbancasset.com/thankyou"

            />

            <input type="hidden" name="_captcha" value="false"></input>
            <h4>Request a call back</h4>
            <label>Your name</label>

            <input type="text" placeholder="" name="name" required />
            <label>Your email</label>
            <input type="email" placeholder="" name="email" required />
            <label>Subject</label>
            <input type="text" placeholder="" name="subject" required />
            <label>Your message</label>
            <textarea
              rows="4"
              cols="15"
              name="message"
              style={{
                outline: "none",
                width: "75%",
                backgroundColor: "#f8fbfd"
              }}
            ></textarea>
            <button className="footer2-form-btn" type="submit">
              Submit
            </button>
            <input
              type="hidden"
              name="_next"
              value="
              http://trustbancasset.com/thankyou"
            />
          </form>
        </div>
        <div className="footer2-card2">
          <div className="footer2-card2-text">
            {" "}
            <h2>Trust us to put you first</h2>
            <p>
              At TrustBanc, our cultural strategy includes putting our clients
              and partner at the center of everything we do.
            </p>
          </div>
          <div className="footer2-card3">
            <img src={Medal} alt="medal" loading="lazy" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer2;
