import React from "react";
import Image47 from "../images/image 47.png";
import Image17 from "../images/image 17-1.png";
import Image18 from "../images/image 18-1.png";
import Image19 from "../images/image 19-1.png";
import Image64 from "../images/image 64.png";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";

function Services() {
  return (
    <div className="service-container">
      <div className="service-text">
        <h4>Our Services</h4>
        <p>
          We allow you to start without having to worry about making a mistake,
          as
          <br /> we use our outstanding experience.
        </p>
      </div>
      <Container className="service-img-container mx-auto p-2">
      <div
          className="row p-5 gap-5 service-img-holder mx-auto p-2"
          data-aos="zoom-in-right"
          data-aos-duration="2000"
        >
           <div className=" p-0 service-img mx-auto p-2">
            <img
              src={Image18}
              alt=""
              style={{ width: "100%", height: "100%", borderRadius: "5px" }}
            />
            <Link to="/mutualfund" style={{ textDecoration: "none" }}>
              <h4>Mutual Funds</h4>
            </Link>
            </div>
          <div className=" p-0 service-img mx-auto p-2">
            <img
              src={Image17}
              alt=""
              style={{ width: "100%", height: "100%", borderRadius: "5px" }}
            />
            <Link to="/wealthmanagement" style={{ textDecoration: "none" }}>
              <h4>Wealth Management</h4>
            </Link>
            </div>
          <div className=" p-0 service-img mx-auto p-2">
            <img
              src={Image19}
              alt=""
              loading="lazy"
              style={{  width: "100%", height: "100%", borderRadius: "5px" }}
            />

            <Link to="/portfoliomanagement" style={{ textDecoration: "none" }}>
              <h4>Portfolio Management</h4>
            </Link>
            </div>
        </div>
        <div
          className="row p-5 gap-5 service-img-holder mx-auto p-2 "
          data-aos="zoom-in-right"
          data-aos-duration="2000"
        >
           <div className=" p-0 service-img mx-auto p-2">
            <img
              src={Image47}
              alt=""
              style={{  width: "100%", height: "100%", borderRadius: "5px" }}
            />
            <Link to="/research" style={{ textDecoration: "none" }}>
              <h4>Research</h4>
            </Link>
          </div>
          <div className=" p-0 service-img mx-auto p-2">
            <img
              src={Image64}
              alt=""
              style={{  width: "100%", height: "100%", borderRadius: "5px" }}
            />
            <Link to="/ethicalinvestment" style={{ textDecoration: "none" }}>
              <h4>
                Alternative Investment
                <br /> (Ethical Investment)
              </h4>
            </Link>
            </div>
          
          </div>
      </Container>
      <div
        className="get-in-touch"
        style={{
          backgroundImage:
            "linear-gradient(92.84deg, #83460F 45.22%, #D07F31 82.47%, #C8721F 98.11%)",
        }}
      >
        <AnimationOnScroll
          animateIn="animate__fadeInLeftBig"
          duration={1}
          playState=""
        >
          <h2>Looking for a lifetime companion for sustainable wealth?</h2>
        </AnimationOnScroll>
        <button className="get-in-touch-btn">
          <Link to="/contact" className="team-links">
            Get in Touch
          </Link>
        </button>
      </div>
    </div>
  );
}

export default Services;
