import React from "react";
import Pin from "../images/Group337.png";
import NavLink2 from "./NavLink2";
import Image18 from "../images/image 18-1.png";
import Image17 from "../images/image 17-1.png";
import Image19 from "../images/image 19-1.png";
import Image47 from "../images/image 47.png";
import Image64 from "../images/image 64.png";
import Maskgroup4 from "../images/Mask group4.png";
import Footer5 from "./Footer5";
import Footer4 from "../compnents/Footer4";
import { Link } from "react-router-dom";

function MutualfundPage() {
  return (
    <div>
      <div className="our-services"
        style={{
          backgroundImage: `url(${Pin})`,
          loading:"lazy",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "150px",
          textAlign: "center",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div>
          <NavLink2 />
          <h1>Our Services</h1>
        </div>
      </div>
      <div className="service-grid mx-auto p-2  justify-content-center">
      <div className="row mb-5 ">
          <div className="service-cards col-sm-12 col-lg-6">
            <h1 style={{}}>Mutual Funds</h1>
            <div className="service-card1">
              <div className="service-img2" >
              <img src={Image18} alt=""className="w-100 h-100 rounded-xl" loading="lazy"/>
              </div>
              <div className="service-info">
                <p>
                TrustBanc Money Market Fund (“The Fund”) is an open-ended Fund
               authorized and registered as a Unit Trust Scheme by the Securities
               & Exchange Commission (SEC).
                  <button className="service-btn">
                    <Link to="/mutualfund" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div className="service-cards col-sm-12 col-lg-6">
              <h1 style={{}}>Wealth Management</h1>
              <div className="service-card1">
                <div className="service-img2">
                <img src={Image17} alt=""className="w-100 h-100 rounded-xl"/></div>
                <div className="service-info">
                  <p>
                    Our investment advisory service provides financial
                    management and wealth advisory services to a wide range of
                    clients...{" "}
                    <button className="service-btn">
                      <Link to="/wealthmanagement" className="service-link">
                        Learn more
                      </Link>{" "}
                      <img src={Maskgroup4} alt="" className="more-img" />
                    </button>
                  </p>
                </div>
              </div>
            </div>
        </div>
        <div className="row mb-5">
        <div className="service-cards col-sm-12 col-lg-6">
            <h1 style={{}}>Portfolio Management</h1>
            <div className="service-card1">
              <div className="service-img2">
              <img src={Image19} alt="" className="w-100 h-100 rounded-xl" /></div>
              <div className="service-info">
                <p>
                Our Private Portfolio Product gives our clients a competitive
              advantage by providing them with tailor mage solutions and
              investments products that suits their finacial goals.
                  <button className="service-btn">
                    <Link to="/portfoliomanagement" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt=""className="more-img" />
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div className="service-cards col-sm-12 col-lg-6">
              <h1 style={{}}>Research</h1>
              <div className="service-card1">
               <div className="service-img2">
                <img src={Image47} alt="" className=" w-100 h-100 rounded-xl" />
                </div>
                 <div className="service-info">
                  <p>
                    Research is our core tool to offer professional advice, we
                    rely on TrustBanc AM research findings to guide all our investment
                    actions on behalf of clients...{" "}
                    <button className="service-btn">
                      <Link to="/research" className="service-link">
                        Learn more
                      </Link>{" "}
                      <img src={Maskgroup4} alt="" className="more-img" />
                    </button>
                  </p>
                </div>
              </div>
            </div>
        </div>
        <div className="row mb-5">
        <div className="service-cards col-sm-12 col-lg-6">
              <h1 style={{}}>Alt Invest (Ethical Investment)</h1>

              <div className="service-card1">
                <div  className="service-img2" >
                <img src={Image64} alt="" className=" w-100 h-100 rounded-xl" /></div>
                <div className="service-info">
                  <p>
                    A special investment product offered to investors that want
                    to earn halal returns on their investments. The product is
                    guided by Islamic Commercial jurisprudence.{" "}
                    <button className="service-btn">
                      <Link to="/ethicalinvestment" className="service-link">
                        Learn more
                      </Link>{" "}
                      <img src={Maskgroup4} alt="" className="more-img" />
                    </button>
                  </p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div>
        <Footer5 />
      </div>
      <div className="footer-4">
        <Footer4 />
      </div>
    </div>
  );
}

export default MutualfundPage;
