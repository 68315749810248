import React from "react";
import NavLink2 from "../compnents/NavLink2";
import Search from "../images/Group333.png";
import Image36 from "../images/image36.png";
import Vector from "../images/Vector6.png";
import Vector7 from "../images/Vector7.png";
import Frame from "../images/Frame289.png";
import Footer5 from "../compnents/Footer5";
import { Link } from "react-router-dom";
import Footer4 from "../compnents/Footer4";

function ReportPage() {
  return (
    <div style={{ backgroundColor: "#E5F0F5", width: "100%" }}>
      {" "}
      <div
        style={{
          backgroundImage: `url(${Search})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "400px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)"
        }}
      >
        <div>
          <NavLink2 />
        </div>
        <div
          style={{
            textAlign: "center",
            color: "white",
            position: "relative",
            top: "100px"
          }}
        >
          <h3
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "50px",
              lineHeight: " 75px",
              color: " #FFFFFF"
            }}
          >
            Research
          </h3>
        </div>
      </div>
      <div className="report-cards">
        <div className="report-card1">
          <div
            className="report-img"
            style={{
              backgroundImage: `url(${Image36})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "400px",

              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)"
            }}
          >
            <button className="report-card1-text">
              <Link to="/report-update">
                {" "}
                <h1>
                  TrustBanc Weekly Market Update,
                  <br /> 17th March, 2023.
                </h1>
                <p>
                  <img
                    src={Vector}
                    alt=""
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                  20th March 2023
                </p>
              </Link>
            </button>
          </div>
          <div className="report-card1-info-holder">
            <div className="report-card1-info">
              {" "}
              <h1>TrustBanc Daily Market Roundup, 16th March, 2023</h1>
              <p>
                <img src={Vector7} alt="" style={{ marginRight: "10px" }} />
                16th March 2023
              </p>
            </div>
            <div className="report-card1-info">
              {" "}
              <h1>TrustBanc Daily Market Roundup, 15th March, 2023</h1>
              <p>
                <img src={Vector7} alt="" style={{ marginRight: "10px" }} />
                15th March 2023
              </p>
            </div>
            <div className="report-card1-info">
              {" "}
              <h1>TrustBanc Daily Market Roundup, 14th March, 2023</h1>
              <p>
                <img src={Vector7} alt="" style={{ marginRight: "10px" }} />
                14th March 2023
              </p>
            </div>
            <div className="report-card1-info">
              {" "}
              <h1>TrustBanc Daily Market Roundup, 13th March, 2023</h1>
              <p>
                <img src={Vector7} alt="" style={{ marginRight: "10px" }} />
                13th March 2023
              </p>
            </div>
          </div>
        </div>
        <div className="report-card2">
          <div className="report-card2-holder">
            <h3>
              <img
                src={Frame}
                alt=""
                style={{ width: "30px", marginRight: "10px" }}
              />
              News Update
            </h3>
            <p>
              <ul>
                <a href="https://trustbanccapital.com/blog/2023/05/05/trustbanc-weekly-market-roundup-5th-may-2023/">
                  <li> TrustBanc Weekly Market Roundup – 5th May 2023 </li>
                </a>
                <a href="https://trustbanccapital.com/blog/2023/05/05/equity-market-closing-pricelist-5th-may-2023/">
                  {" "}
                  <li>Equity Market Closing Pricelist – 5th May 2023 </li>
                </a>{" "}
                <a href="https://trustbanccapital.com/blog/2023/05/04/trustbanc-daily-market-roundup-4th-may-2023/">
                  {" "}
                  <li>TrustBanc Daily Market Roundup – 4th May 2023 </li>
                </a>
                <a href="https://trustbanccapital.com/blog/2023/05/04/equity-market-closing-pricelist-4th-may-2023/">
                  {" "}
                  <li>Equity Market Closing Pricelist – 4th May 2023</li>
                </a>
                <a href="https://trustbanccapital.com/blog/2023/05/03/trustbanc-daily-market-roundup-3rd-may-2023">
                  {" "}
                  <li>TrustBanc Daily Market Roundup – 3rd May 2023 </li>
                </a>
                <a href="https://trustbanccapital.com/blog/2023/05/03/equity-market-closing-pricelist-3rd-may-2023/">
                  <li>Equity Market Closing Pricelist – 3rd May 2023</li>{" "}
                </a>
                <a href="https://trustbanccapital.com/blog/2023/05/02/equity-market-closing-pricelist-2nd-may-2023/">
                  {" "}
                  <li>Trustbanc Daily Market Roundup – 2nd May 2023</li>
                </a>
                <a href="https://trustbanccapital.com/blog/2023/05/02/equity-market-closing-pricelist-2nd-may-2023/">
                  <li>Equity Market Closing Pricelist – 2nd May 2023</li>
                </a>
                <a href="https://trustbanccapital.com/blog/2023/05/02/trustbanc-monthly-market-roundup-28th-april-2023/">
                  <li>Trustbanc Monthly Market Roundup – 28th April 2023</li>
                </a>
                <a href="https://trustbanccapital.com/blog/2023/04/28/equity-market-closing-pricelist-28th-april-2023/">
                  <li>Equity Market Closing Pricelist – 28th April 2023</li>
                </a>
              </ul>
            </p>
          </div>
          <div className="report-card2-holder">
            <h3>
              <img
                src={Frame}
                alt=""
                style={{ width: "30px", marginRight: "10px" }}
              />
              African Stock Markets
            </h3>
            <p>
              <ul>
                <a href="https://www.african-markets.com/en/stock-markets/zse/zimbabwe-seed-co-limited-board-approves-migration-to-vfex-listing">
                  <li>
                    Zimbabwe: Seed Co Limited Board Approves Migration To VFEX
                    Listing
                  </li>
                </a>
                <a href="https://www.african-markets.com/en/stock-markets/egx/vodacom-acquires-additional-stake-in-vodafone-e">
                  <li>Vodacom acquires additional stake in Vodafone Egypt</li>
                </a>
                <a href="https://www.african-markets.com/en/stock-markets/gse/ghana-stock-exchange-grants-listed-banks-extension-for-filing-2022-audited-financial-statements">
                  {" "}
                  <li>
                    Ghana Stock exchange grants listed banks extension for
                    filing 2022 audited financial statements
                  </li>
                </a>
                <a href="https://www.african-markets.com/en/stock-markets/mse/malawi-stock-exchange-picks-up-in-3-weeks">
                  {" "}
                  <li>Malawi Stock Exchange picks up in 3 weeks</li>
                </a>
                <a href="https://www.african-markets.com/en/stock-markets/nse/nairobi-securities-exchange-day-trading-yet-to-gain-traction-in-first-year">
                  {" "}
                  <li>
                    Nairobi Securities Exchange: Day-trading yet to gain
                    traction in first year
                  </li>
                </a>
                <a href="https://www.african-markets.com/en/stock-markets/gse/press-spotlights-bullish-performance-by-ghanaian-bourse-in-2022-othe">
                  {" "}
                  <li>Centum cancels sale of Sidian Bank to Access</li>
                </a>
              </ul>
            </p>
          </div>
        </div>
        <div className="report-card3">
          <div className="report-card2-holder">
            <h3>
              <img
                src={Frame}
                alt=""
                style={{ width: "30px", marginRight: "10px" }}
              />
              See Mutual Funds Fact Sheet
            </h3>
            <p>
              <ul>
                <li>Trustbanc Mutual Funds Fact Sheet – February 2023</li>
                <li>Trustbanc Mutual Funds Fact Sheet – January 2023</li>
                <li>Trustbanc Mutual Funds Fact Sheet – December 2022</li>
                <li>Trustbanc Mutual Funds Fact Sheet – November 2022</li>
                <li>Trustbanc Mutual Funds Fact Sheet – October 2022</li>
                <li>Trustbanc Mutual Funds Fact Sheet– September 2022</li>
                <li>Trustbanc Mutual Funds Fact Sheet – August 2022</li>
                <li>Trustbanc Mutual Funds Fact Sheet – July 2022</li>
                <li>Trustbanc Mutual Funds Fact Sheet – May 2022</li>
                <li>Trustbanc Mutual Funds Fact Sheet– April 2022</li>
                <li>Trustbanc Mutual Funds Fact Sheet– March 20222</li>
                <li>Trustbanc Mutual Funds Fact Sheet– February 2022</li>
                <li>Trustbanc Mutual Funds Fact Sheet– January 2022</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <>
        <Footer5 />{" "}
      </>
      <div className="footer-4">
        <Footer4 />
      </div>
    </div>
  );
}

export default ReportPage;
