import React from "react";
import NavLink2 from "../compnents/NavLink2";
import Pin from "../images/Group337.png";
import { Link } from "react-router-dom";
import Image48 from "../images/image 48.png";
import Maskgroup4 from "../images/Mask group4.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image90 from "../images/image 90.png";
import Image91 from "../images/image 91.png";
import Image49 from "../images/image 49.png";
import Footer5 from "../compnents/Footer5";
import Image50 from "../images/image 50.png";
import TrustbancKids from "../images/TrustBanc Kids.png";
import Footer4 from "../compnents/Footer4";

import Image51 from "../images/image 51.png";

function WealthManagementproducts() {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${Pin})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "400px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)"
        }}
      >
        <div>
          <NavLink2 />
        </div>
        <div
          style={{
            textAlign: "center",
            color: "white",
            position: "relative",
            top: "100px"
          }}
        >
          <h3
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "50px",
              lineHeight: " 75px",
              color: " white"
            }}
          >
            Wealth Management
          </h3>
        </div>
      </div>
      <div className="mutual-cards">
        {" "}
        <h4>TrustBanc Fixed Income Portfolio</h4>
        <div className="mutual-card1">
          <img src={Image48} alt="" className="mutual-img" />
          <div className="mutual-info">
            <p>
              The product seeks to return premium yield to its investors. The
              authorized investment classes in the product include
              naira-denominated insured credit instruments, and fixed-income
              instruments to manage liquidity. The product is aimed at investors
              who are interested in Naira-denominated income and seeking to
              diversify their existing fixed-income exposure.
              <br />
              <br />
              <ul>
                <b>Features</b>
                <br />
                <li>Minimum investible amount of N5m.</li>
                <li>Quick redemption (24hrs time frame)</li>
                <li>No administrative charges or fees</li>
                <li>Easy tracking via our digital platform</li>
                <li>
                  Additions to and withdrawals from investments are acceptable
                  (subject to 20% penalty on accrued interest for withdrawals
                  made before maturity).
                </li>
                <li>
                  An investment certificate will be available for all invested
                  funds.
                </li>
              </ul>
              <ul>
                <b> Benefits</b>
                <br />
                <li>Flexible structure with variable investment tenors</li>
                <li>Capital Preservation</li>
                <li>Competitive return on investment </li>
                <li>Tax-free rate of return</li>
                <li>
                  Professionally Managed by TrustBanc Asset Management and
                  regulated by the Securities and Exchange Commission, SEC
                </li>
              </ul>
            </p>

            <Link to="/contact" className="contact-link">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
      <Container className="service-grid">
        <Row data-aos="zoom-in-up" style={{ marginTop: "100px" }}>
          <h3 style={{ fontSize: "20px", marginLeft: "170px", color: "black" }}>
            OUR PRODUCTS
          </h3>
          <Col className="service-cards" sm={12}>
            <h1 style={{}}>TrustBanc Eurobond Margin Loan</h1>
            <div className="service-card1">
              <img src={Image51} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  The TrustBanc Eurobond Margin Loan is a USD-dominant product
                  designed to allow interested clients who do not have the
                  $200,000 minimum investment amount at once.....
                  <button className="service-btn">
                    <Link to="/product6" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>

          <Col className="service-cards" sm={12}>
            <h1 style={{}}>TrustBanc Asset Management Note (Backend)</h1>
            <div className="service-card1">
              <img src={Image90} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  This product is designed to allow both individual and
                  Institutional investors have access to an investment that
                  maximize investors returns and ensure capital preservation.
                  <button className="service-btn">
                    <Link to="/product2" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row
          className="justify-content-md-center"
          data-aos="zoom-in-up"
          style={{ marginBottom: "100px", marginTop: "100px" }}
        >
          <Col className="service-cards" sm={12}>
            <h1 style={{}}>TrustBanc Asset Management Note (Upfront)</h1>
            <div className="service-card1">
              <img src={Image91} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  A flexible investment focused on maximizing returns without
                  compromising risk.The product allows clients have access to a
                  secure investment that guarantees .......
                  <button className="service-btn">
                    <Link to="/product3" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>
          <Col className="service-cards" sm={12}>
            <h1 style={{}}>TrustBanc Euroinvest Dollar Product</h1>

            <div className="service-card1">
              <img src={Image49} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  A special investment product offered to investors that want to
                  earn halal returns on their investments. The product is guided
                  by Islamic Commercial jurisprudence.{" "}
                  <button className="service-btn">
                    <Link to="/product4" className="service-link">
                      See more products
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row
          className=""
          data-aos="zoom-in-up"
          style={{
            marginBottom: "100px",
            marginTop: "100px",
            display: "flex",
            marginLeft: "35px",
            justifyContent: "space-between"
          }}
        >
          <Col className="service-cards" md="auto">
            <h1 style={{}}>
              {" "}
              <img src={TrustbancKids} alt="" style={{ width: "400px" }} />
            </h1>
            <div className="service-card1">
              <img src={Image50} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  There is no compromise for parents when it comes to securing
                  their child’s future. That is why TrustBanc Kids Save is
                  designed to help parents have access to an investment......
                  <button className="service-btn">
                    <Link to="/product5" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>
          <Col className="service-cards" md="auto"></Col>
        </Row>
      </Container>
      <div>
        <Footer5 />
      </div>
      <div className="footer-4">
        <Footer4 />
      </div>
    </div>
  );
}

export default WealthManagementproducts;
