import React from "react";
import Arrow from "../images/arrow.png";
import Moneychart from "../images/moneychart.png";
import Moneytree from "../images/money-tree.png";
import Medal from "../images/medal.png";
import Grassarrow from "../images/grass-arrow.png";
import Carousel from "react-bootstrap/Carousel";

export const Carosel = () => {
  return (
    <div>
      <Carousel
        fade
        className="carosel-container"
        controls={false}
        pause={"hover" | false}
      >
        <Carousel.Item>
          <div className="carosel-card">
            <div className="carosel-text">
              <h1>Looking to achieve your financial goals?</h1>
              <p>
                We offer a broad range of financial solutions and products to
                meet the unique needs of our clients.
              </p>
            </div>
            <div className="carosel-img">
              <img src={Arrow} className="arrow" alt="loading" loading="lazy" />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carosel-card">
            <div className="carosel-text">
              <h1>Good things come to those who invest</h1>
              <p>
                Enjoy investment return free of tax and administrative fees
                burden.
              </p>
            </div>
            <div className="carosel-img">
              <img
                src={Moneychart}
                className="arrow"
                alt="loading"
                loading="lazy"
              />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carosel-card">
            <div className="carosel-text">
              <h1>Growth and Preservation</h1>
              <p>
                We will give you a fresh perspective on securitized investment
                opportunities, whether it is growing your wealth or planning for
                your future.
              </p>
            </div>
            <div className="carosel-img">
              <img
                src={Moneytree}
                className="arrow"
                alt="loading"
                loading="lazy"
              />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carosel-card">
            <div className="carosel-text">
              <h1>Trust us to put you first</h1>
              <p>
                At TrustBanc, our strategy includes putting our clients and
                partners at the center of everything we do.
              </p>
            </div>
            <div className="carosel-img">
              <img src={Medal} className="arrow" alt="medal" loading="lazy" />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carosel-card">
            <div className="carosel-text">
              <h1>Make your choice now</h1>
              <p>Start earning income on any of your product choice.</p>
            </div>
            <div className="carosel-img">
              <img
                src={Grassarrow}
                className="arrow"
                alt="logo"
                loading="lazy"
              />
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
