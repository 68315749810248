import "./App.css";
import NavLink from "./compnents/NavLink";
import About from "./compnents/About";
import { Carosel } from "./compnents/Carosel";
import Attribution from "./compnents/Attribution";
import Services from "./compnents/Services";
import Footer1 from "./compnents/Footer1";
import Footer2 from "./compnents/Footer2";
import Footer3 from "./compnents/Footer3";
import Footer4 from "./compnents/Footer4";
import Footer5 from "./compnents/Footer5";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import "aos/dist/aos.css";
// import AnimatedCursor from "react-animated-cursor";

function App() {
  return (
    <div>
      <NavLink />
      <Carosel />
      <About />
      <Attribution />
      <Services />
      <Footer1 />
      <Footer2 />
      <Footer3 />
      <Footer4 />
      <Footer5 />
    </div>
  );
}

export default App;
