import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";

const StatementForm = ({ setShowDiv2 }) => {
  const [accountname, setAccountname] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [statementtype, setStatementtype] = useState("");
  const [usestampduty, setUsestampduty] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [orderrequest, setOrderrequest] = useState("");

  const handleRequest = async (e) => {
    e.preventDefault();
    const StatementData = {
      accountname,
      email,
      phonenumber,
      statementtype,
      usestampduty,
      from,
      country,
      address,
      description,
      to,
      orderrequest
    };

    try {
      const response = await fetch(
        "https://tptest.trustbancgroup.com:4443/MFB_USSD/api/v1/asset/statementrequest",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(StatementData)
        }
      );

      const responseData = await response.json();

      if (responseData.status === true) {
        Swal.fire({
          icon: "success",
          title: "Thank You!",
          text: responseData.responsemessage
        });
        setTimeout(() => {
          window.location.reload(); // Reload the page after a delay
        }, 4000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: responseData.message,
          showConfirmButton: true
        });
      }
    } catch (error) {
      if (error.response) {
        // Server responded with an error status code
        const errorMessage = await error.response.json();
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: errorMessage.status,
          showConfirmButton: true
        });
      } else {
        // Request was made but no response received or network error occurred
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: "An error occurred. Please try again later.",
          showConfirmButton: true
        });
      }
    }
  };

  const handleSelectChange = (e) => {
    setStatementtype(e.target.value);
  };

  return (
    <div className="form-container">
      <button
        onClick={() => {
          setShowDiv2(true);
        }}
        className="close-btn"
      >
        <CloseIcon style={{ fontSize: "35px" }} />
      </button>
      <div className="form-card">
        <Form className="request-form" onSubmit={handleRequest}>
          <h5
            style={{
              color: "#044188",
              fontSize: "16px",
              fontWeight: "600"
            }}
          >
            Request
          </h5>
          <Form.Group className="mb-2" controlId="formGroupEmail">
            <Form.Label>Account Name</Form.Label>
            <Form.Control
              required
              type="text"
              name="accountname"
              value={accountname}
              onChange={(e) => setAccountname(e.target.value)}
              placeholder=""
              style={{ outline: "none" }}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="formGroupEmail">
            <Form.Label>Email </Form.Label>
            <Form.Control
              type="email"
              placeholder=""
              required
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="formGroupEmail">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="tel"
              required
              placeholder=""
              name="phonenumber"
              value={phonenumber}
              onChange={(e) => setPhonenumber(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="formGroupEmail">
            <Form.Label>Choose Product</Form.Label>
            <Form.Select
              aria-label=""
              name="statementtype"
              value={statementtype}
              onChange={(e) => {
                handleSelectChange(e);
                setStatementtype(e.target.value);
              }}
            >
              <option>Select Request Type</option>
              <option value="CASH STATEMENT"> CASH STATEMENT</option>
              <option value="VALUATION STATEMENT">VALUATION STATEMENT</option>
              <option value="REFERENCE / EMBASSY LETTER">
                REFERENCE / EMBASSY LETTER
              </option>
              <option value="COMPLAINTS">COMPLAINTS</option>
            </Form.Select>
          </Form.Group>
          {statementtype === "COMPLAINTS" ? (
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Add Complaints"
                name="complaints"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          ) : statementtype === "REFERENCE / EMBASSY LETTER" ? (
            <>
              <Form.Group className="mb-2" controlId="formGroupCountry">
                <Form.Label>Select Country</Form.Label>
                <Form.Select
                  aria-label=""
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option>Select Country</option>
                  <option value="UK">UK</option>
                  <option value="Canada">Canada</option>
                  <option value="Schengen Visa">Schengen Visa</option>

                  <option value="Others">Others</option>
                </Form.Select>
              </Form.Group>
              <div className="form-check form-check-reverse">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="usestampduty"
                  checked={usestampduty}
                  onChange={(e) => setUsestampduty(e.target.checked)}
                />
                <label className="form-check-label">
                  CLICK BOX IF THE STATEMENT SHOULD BE STAMPED AND SIGNED
                </label>
              </div>
              <Form.Group className="mb-2" controlId="formGroupAddress">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Address"
                  name="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Group>
            </>
          ) : (
            <>
              <div className="form-check form-check-reverse">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="usestampduty"
                  checked={usestampduty}
                  onChange={(e) => setUsestampduty(e.target.checked)}
                />
                <label className="form-check-label">
                  CLICK BOX IF THE STATEMENT SHOULD BE STAMPED AND SIGNED
                </label>
              </div>
              <Form.Group className="mb-2" controlId="formGroupEmail">
                <Form.Label>From</Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  name="from"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-2" controlId="formGroupEmail">
                <Form.Label>To</Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  name="to"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Other Request</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="orderrequest"
                  value={orderrequest}
                  onChange={(e) => setOrderrequest(e.target.value)}
                />
              </Form.Group>
            </>
          )}
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </Form>
      </div>
    </div>
  );
};

export default StatementForm;
