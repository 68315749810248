import React, { useEffect } from "react";
import Image19 from "../images/image 19-1.png";
import Portfolio from "../images/Group334.png";
import NavLink2 from "../compnents/NavLink2";
import Image47 from "../images/image 47.png";
import { Link } from "react-router-dom";
import Maskgroup4 from "../images/Mask group4.png";
import Image18 from "../images/image 18-1.png";
import Image64 from "../images/image 64.png";
import Footer5 from "../compnents/Footer5";
import Footer4 from "../compnents/Footer4";
import Right from "../images/right-arrow.png";
import Image17 from "../images/image 17-1.png";

function ResearchPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      {" "}
      <div
        className="main-MF"
        style={{
          backgroundImage: `url(${Portfolio})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "150px",
          textAlign: "center",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)"
        }}
      >
        <div>
          <NavLink2 />
          <h1> Research</h1>
        </div>
        <div
          style={{
            textAlign: "center",
            color: "white",
            position: "relative",
            top: "100px"
          }}
        ></div>
      </div>
      <div className="mutual-cards">
        {" "}
        <h4 className="header4">Research</h4>
        <div className="mutual-card1">
          <div className="research-mutual-img-holder">
            <img src={Image47} alt="" className="research-mutual-img" />
          </div>
          <div className="research-mutual-info">
            <p>
              At TrustBanc Asset Management, we rely on research findings to
              guide all the investment decisions made on behalf of our clients.
              <br />
              Our research team delivers astute, sovereign, and timely.
              <br />As a West African focused wealth management firm, we provide
              inclusive research coverage of Nigeria and other Africa countries
              within the sub-region to assist our clients in making informed
              decision on their portfolios for commensurate returns.
              <br />
              TrustBanc AM  research covers in-depth analysis, recommendation and
              selection of assets for optimum returns to build wealth. <br />
              Our focus is:
              <br /> <img src={Right} alt="" style={{ width: "20px" }} />
              Market Report
              <br /> <img src={Right} alt="" style={{ width: "20px" }} />
              Price List
              <br /> <img src={Right} alt="" style={{ width: "20px" }} />
              Quarterly Report
              <br /> <img src={Right} alt="" style={{ width: "20px" }} />
              Annual Report
              <br /> <img src={Right} alt="" style={{ width: "20px" }} />
              Mutual Fund Fact Sheet
              <p> Please contact us for more information</p>
            </p>

            <a href="https://trustbanccapital.com/blog/">
              <button className="portfolio-link">Proceed to Report</button>
            </a>
          </div>
        </div>
      </div>{" "}
      <div className="service-grid mx-auto p-2  justify-content-center">
        <div className="row mb-5" data-aos="zoom-in-up">
          <h3>WE ALSO OFFER</h3>
          <div className="service-cards col-sm-12 col-lg-6">
            <h1 style={{}}>Mutual Funds</h1>
            <div className="service-card1">
              <div className="service-img2">
                {" "}
                <img src={Image18} alt="" className=" w-100 h-100 rounded-xl" />
              </div>
              <div className="service-info">
                <p>
                  TrustBanc Money Market Fund (“The Fund”) is an open-ended Fund
                  authorized and registered as a Unit Trust Scheme by the
                  Securities & Exchange Commission (SEC).
                  <button className="service-btn">
                    <Link to="/mutualfund" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div className="service-cards col-sm-12 col-lg-6">
            <h1 style={{}}>Wealth Management</h1>
            <div className="service-card1">
              <div className="service-img2">
                <img src={Image17} alt="" className=" w-100 h-100 rounded-xl" />
              </div>{" "}
              <div className="service-info">
                <p>
                  Our investment advisory service provides financial management
                  and wealth advisory services to a wide range of clients...{" "}
                  <button className="service-btn">
                    <Link to="/wealthmanagement" className="service-link">
                      Learn more{" "}
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row" data-aos="zoom-in-up">
          <div className="service-cards col-sm-12 col-lg-6">
            <h1 style={{}}>Portfolio Management</h1>
            <div className="service-card1">
              <div className="service-img2">
                <img src={Image19} alt="" className=" w-100 h-100 rounded-xl" />
              </div>{" "}
              <div className="service-info">
                <p>
                  Our Private Portfolio Product gives our clients a competitive
                  advantage by providing them with tailor mage solutions and
                  investments products that suits their finacial goals.
                  <button className="service-btn">
                    <Link to="/portfoliomanagement" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </div>

          <div className="service-cards col-sm-12 col-lg-6">
            <h1 style={{}}>Alternative Investment (Ethical Investment)</h1>

            <div className="service-card1">
              <div className="service-img2">
                <img src={Image64} alt="" className=" w-100 h-100 rounded-xl" />
              </div>{" "}
              <div className="service-info">
                <p>
                  A special investment product offered to investors that want to
                  earn Halal returns on their investments. The product is guided
                  by Islamic commercial jurisprudence.{" "}
                  <button className="service-btn">
                    <Link to="/ethicalinvestment" className="service-link">
                      Learn more{" "}
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer5 />
      </div>
      <div className="footer-4">
        <Footer4 />
      </div>
    </div>
  );
}

export default ResearchPage;
