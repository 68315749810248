import React from 'react'
import NavLink2 from "../compnents/NavLink2";
import Pin from "../images/Group337.png";
import { Link } from "react-router-dom";
import Image48 from "../images/image 48.png";
import Image50 from "../images/image 50.png";
import TrustbancKids from "../images/TrustBanc Kids.png";
import Maskgroup4 from "../images/Mask group4.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Image90 from "../images/image 90.png";
import Image91 from "../images/image 91.png";
import Image49 from "../images/image 49.png";
import Footer5 from "../compnents/Footer5";
import Image51 from "../images/image 51.png";
import Footer4 from "../compnents/Footer4";


const EuroBond = () => {
  return (
    <div>
    <div
      style={{
        backgroundImage: `url(${Pin})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "400px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div>
        <NavLink2 />
      </div>
      <div
        style={{
          textAlign: "center",
          color: "white",
          position: "relative",
          top: "100px",
        }}
      >
        <h3
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "50px",
            lineHeight: " 75px",
            color: " white",
          }}
        >
          Wealth Management
        </h3>
      </div>
    </div>
    <div className="mutual-cards">
        {" "}
        <h4> TrustBanc Eurobond Margin Loan</h4>
        <div className="mutual-card1">
          <img src={Image51} alt="" className="mutual-img" />
          <div className="mutual-info">
            <p>
              The TrustBanc Eurobond Margin Loan is a USD-dominant product
              designed to allow interested clients who do not have the $200,000
              minimum investment amount at once to spread their contributions
              over the course of one to three years. This Product enables
              customers to benefit from the market’s attractive yields for
              Eurobonds whenever the opportunity arises.
              <br />
              <ul>
                <b>Features</b>
                <br /> Purpose: To allow clients to take advantage of attractive
                yields whenever opportunities arise at short notice.
              </ul>
              <ul>
                Investor’s Risk Profile:
                <br />
                <li>
                  Clients that have high-risk tolerance and understand the risks
                  of using leverage to meet financial goals.
                </li>
                <li>
                  Clients with medium risk tolerance who intend to hold the
                  Eurobond to maturity{" "}
                </li>
              </ul>
              <span>Minimum Series Size:</span>
              $500,000 (50 Lot)
              <span>Minimum Lot Size:</span>
              $10,000 per Lot
              <span>Pricing:</span>
              <br />
              In line with market conditions and cost of funding in USD
              <br />
              Asset Class:
              <br />
              Eurobondsbr
              <br />
              <ul>
               <b>Product Benefits to Investors</b> 
                <li>
                  Ability to take advantage of volatility in the Eurobonds
                  market at short notice.
                </li>
                <li>
                  Helps to grow assets in a more sustained manner if holding to
                  maturity.
                </li>
                <li>
                  Periodic payments to allows clients with less than $200,000
                  access the Eurobonds market.
                </li>
                <li>Access to investment margin loan</li>
              </ul>
            </p>
            <Link to="/contact" className="contact-link">
              Contact Us
            </Link>
          </div>
        </div>
      </div>{" "} 
      <Container className="service-grid">
        <Row
          className="justify-content-md-center"data-aos="zoom-in-up"
          style={{ marginTop: "100px" }}
        >
          <h3 style={{ fontSize: "20px", marginLeft: "170px", color: "black" }}>
            OUR PRODUCTS
          </h3>
          <Col className="service-cards" md="auto">
            <h1 style={{}}>TrustBanc Fixed Income Portfolio</h1>
            <div className="service-card1">
              <img src={Image48} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  The product seeks to return premium yield to its investors.
                  The authorized investment classes in the product include
                  naira-denominated insured credit instruments.....
                  <button className="service-btn">
                    <Link to="/product1" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>

          <Col className="service-cards" md="auto">
            <h1 style={{}}>TrustBanc Asset Management Note (Backend)</h1>
            <div className="service-card1">
              <img src={Image90} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  This product is designed to allow both individual and
                  Institutional investors have access to an investment that
                  maximize investors returns and ensure capital preservation.
                  <button className="service-btn">
                    <Link to="/product2" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row
          className="justify-content-md-center"data-aos="zoom-in-up"
          style={{ marginBottom: "100px", marginTop: "100px" }}
        >
          <Col className="service-cards" md="auto">
            <h1 style={{}}>TrustBanc Asset Management Note (Upfront)</h1>
            <div className="service-card1">
              <img src={Image91} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  A flexible investment focused on maximizing returns without
                  compromising risk.The product allows clients have access to a
                  secure investment that guarantees .......
                  <button className="service-btn">
                    <Link to="/product3" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>
          <Col className="service-cards" md="auto">
            <h1 style={{}}>TrustBanc Euroinvest Dollar Product</h1>

            <div className="service-card1">
              <img src={Image49} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  A special investment product offered to investors that want to
                  earn halal returns on their investments. The product is guided
                  by Islamic Commercial jurisprudence.{" "}
                  <button className="service-btn">
                    <Link to="/product4" className="service-link">
                    Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row
          className=""data-aos="zoom-in-up"
          style={{
            marginBottom: "100px",
            marginTop: "100px",
            display: "flex",
           marginLeft:"35px",
            justifyContent: "space-between",
          }}
        >
          <Col className="service-cards" md="auto">
            <h1 style={{}}>
              {" "}
              <img src={TrustbancKids} alt="" style={{ width: "400px" }} />
            </h1>
            <div className="service-card1">
              <img src={Image50} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  There is no compromise for parents when it comes to securing
                  their child’s future. That is why TrustBanc Kids Save is
                  designed to help parents have access to an investment......
                  <button className="service-btn">
                    <Link to="/product5" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>
         <Col className="service-cards" md="auto"></Col>
        </Row>
      </Container>
    <div>
      <Footer5 />
    </div>
    <div className="footer-4">
      <Footer4 /></div>
  </div>
  )
}

export default EuroBond