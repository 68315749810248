import React from "react";
import Feather from "../images/feather.png";
import Compass from "../images/compass.png";
import Maskgroup from "../images/maskgroup.png";
import { Link } from "react-router-dom";

function About() {
  return (
    <div className="about-container">
      <div className="about-text">
        <h4>Who Are We?</h4>
        <p>
          TrustBanc Asset Management Limited is licensed and regulated by the
          Securities and Exchange Commission (SEC) as a Fund/Portfolio manager.
          Establish in 2014 through the acquisition of Bridge point Asset
          Management Limited. The Company has now grown to become one of the
          biggest fund managers in the market. TrustBanc Asset Management Ltd.
          delivers a broad range of financial solutions and products to meet the
          unique needs of its clients.
        </p>
      </div>
      <div className="about-card-holder">
        <div data-aos="fade-right" data-aos-duration="2000">
          <div className="card-img">
            <img
              src={Feather}
              className="feather-img"
              alt="About us"
              loading="lazy"
            />
          </div>
          <div className="about-card">
            <h4> About TrustBanc AM</h4>
            <p>
              TrustBanc AM was established in 2014 through the acquisition of
              Bridge Point Asset Management Limited.
            </p>
            <button
              style={{
                border: "none",
                backgroundColor: "inherit",
                color: "#ffff"
              }}
            >
              <Link to="/about" className="team-links">
                {" "}
                Read more <img src={Maskgroup} className="more-img" alt="" />
              </Link>
            </button>
          </div>
        </div>
        <div data-aos="fade-left" data-aos-duration="2000">
          <div>
            <img
              src={Compass}
              className="compass-img"
              alt="vission"
              loading="lazy"
            />
          </div>
          <div className="about-card">
            <h4> Our Vision Statement</h4>
            <p>
              To be the preferred provider of seamless integrated financial
              solutions to our clients”
            </p>
            <button
              style={{
                border: "none",
                backgroundColor: "inherit",
                color: "#ffff"
              }}
            >
              <Link to="/about" className="team-links">
                {" "}
                Read more <img src={Maskgroup} className="more-img" alt="" />
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
