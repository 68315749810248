import React from "react";
import Team from "../images/team.png";
import NavLink2 from "../compnents/NavLink2";
import Footer5 from "../compnents/Footer5";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Profile12 from "../images/image 59.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Profile13 from "../images/image 61.png";
import Profile14 from "../images/image 62.png";
import Profile15 from "../images/image 63.png";
import Container from "react-bootstrap/esm/Container";
import Footer4 from "../compnents/Footer4";

function MyVerticallyCenteredModal1(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ display: "flex", padding: "30px" }}>
        <div className="profile-box">
          <img
            src={Profile12}
            alt=""
            className="profile-img"
            style={{ marginBottom: "20px" }}
          />
          <h4>Lateef Onireti Ibraheem</h4>
          <p>Chairman​</p>
        </div>
        <p style={{ marginLeft: "30px" }}>
          Prof. Ibraheem is a Reader (Associate Professor) in the Department of
          Arabic, University of Ilorin, Kwara State. He Joined the University of
          Ilorin as an assistant lecturer and researcher in Arabic in 2006.
          Prior to joining the University of Ilorin, he was a Lecturer at the
          Kwara State College of Arabic and Islamic Legal 1998 – 2005. He is the
          representative of the Faculty of Arts to the Senate of University of
          Ilorin, the Chairman, Faculty of Arts Technical Committee on Result
          Computation, Member, Technical Committee, Faculty of Arts Postgraduate
          Board of Studies. Postgraduate Programme Coordinator, Department of
          Arabic. He was the HOD, Department of Arabic, 2016 -2018. The Faculty
          of Arts representative to Faculty of Communication and Information
          Sciences (CIS), Unilorin 2017 to 2019, Faculty of Arts representative
          to Faculty of Law, 2015- 2017, Secretary, Editorial Board, Alimi
          Journal of Arabic Studies (AJAS), 2013 to 2017, Assistant Director,
          Advancement Centre (AC), University of Ilorin, 2013-2016, Assistant
          Director (Operation) at the Centre for International Education (CIE),
          Unilorin. 2010-2013. He is a Fellow of African Humanity Program
          awarded by American Council of Learned Societies (ACLS). He is the
          Mufti (Ground Jurist) of Ojoku land and Chief Imam and Murshid
          Badruddinil-Islami Assalat Circle of Nigeria and Member of Local,
          National and International Learned Association.
        </p>
      </Modal.Body>
    </Modal>
  );
}

function MyVerticallyCenteredModal2(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ display: "flex", padding: "30px" }}>
        <div className="profile-box">
          <img
            src={Profile13}
            alt=""
            className="profile-img"
            style={{ marginBottom: "20px" }}
          />
          <h4>Abdulqadir Ibrahim Abikan</h4>
          <p>Managing Director​</p>
        </div>
        <p style={{ marginLeft: "30px" }}>
          Prof. Abdulqadir Ibrahim Abikan is a member of the Governing Council
          of the University of Ilorin. He obtained LL.B from the Bayero
          University in 1997, LL.M from Obafemi Awolowo University in 2003, and
          Ph.D. in Law from the International Islamic University, Malaysia in
          2007. He joined University of Ilorin as an assistant lecturer in 2001
          and rose to become a professor of Islamic Law in 2017. He was sub-Dean
          of Law/Chief Examination Officer (2008-2010), Postgraduate
          Coordinator, Department if Islamic law (2010-2012), Dean of Law
          (2012-2014), the facilitator and Dean of Law Alt-Hikmah University
          Ilorin (2014-2015), the director, School of Preliminary Studies
          (2017-2019). He has supervised several theses on Islamic Law and
          Finance, notable amongst them are; An Analysis of the Legal Framework
          for Dispute Resolution in Islamic Banking and Finance, Operational
          Framework of Islamic Judiciary in Nigeria: A case Study of Shariah
          Court of Appeal Ilorin, Establishing Micro Islamic Banks: A Panacea to
          the Protracted Islamic Financing in Nigeria. Human Rights and
          Treatment of Minorities Under the Shari’ah.{" "}
        </p>
      </Modal.Body>
    </Modal>
  );
}

function MyVerticallyCenteredModal3(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ display: "flex", padding: "30px" }}>
        <div className="profile-box">
          <img
            src={Profile14}
            alt=""
            className="profile-img"
            style={{ marginBottom: "20px" }}
          />
          <h4>Muritala Kewuyemi Kareem</h4>
          <p>Member​</p>{" "}
        </div>
        <p style={{ marginLeft: "30px" }}>
          Muritala is an astute academician with experience spanning over 31
          years. He is a lecturer of Arabic and Islamic Studies at the
          University of Ibadan, and is known for his resourcefulness and
          expertise on Islamic matters. Muritala has a bachelor’s and Master of
          Arts degree in Arabic and Islamic Studies from the University of
          Ibadan, as well as a doctorate degree from the same institution. He is
          a member, Institute of Chartered Accountants of Nigeria, African
          Society for the Study of Sociology and Ethics of Religion, The
          International Association of Islamic Economic System, Leicester, The
          United Kingdom, Member, Nigerian Association of Teachers of Arabic and
          Islamic Studies (NATAIS), Member, The Nigerian Economic Society (NES).
          He has conducted various research works, projects, dissertation, and
          thesis in Islamic Studies.
        </p>
      </Modal.Body>
    </Modal>
  );
}

function MyVerticallyCenteredModal4(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ display: "flex", padding: "30px" }}>
        <div className="profile-box">
          <img
            src={Profile15}
            alt=""
            className="profile-img"
            style={{ marginBottom: "20px" }}
          />
          <h4>Omotayo Wright</h4>
          <p>Member​</p>{" "}
        </div>
        <p style={{ marginLeft: "30px" }}>
        A chartered accountant with thirteen years of banking expertise. He has played a vital role in series of automation of the Non-Interest Banking operational processes, engagement with relevant stakeholders on process improvement, conducted several streams of regional trainings to branch operations officers on process and application improvement.
Omotayo began his career as a Senior Accounts Personnel with Ekocorp PLC in 2008, before moving to Sterling Bank PLC in 2009, he was the Deputy Head, Non-Interest Banking Operations in Sterling Bank PLC from 2015 to 2021 before resigning to join TrustBanc Arthur as the Operations Team.
Omotayo has a National Diploma in Accountancy from Osun State Polytechnic, Executive Diploma in Islamic Banking and Finance, B.Sc. degree in Accounting from the University of Lagos. He is a member, Institute of Chartered Accountants of Nigeria. He has undertaken various training programs including Compliance training on Anti Money Laundering, Basic Banking Operation Course (Averti Professional Managers), Customer Service & Business Communication Course (Copley Training Limited), Basic Banking Operations (Financial Institutions Training Centre), Cheque Verification and its significance amongst others.
        </p>
      </Modal.Body>
    </Modal>
  );
}

function AdvisorycommiteePage() {
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const [modalShow4, setModalShow4] = React.useState(false);

  return (
    <div className="team-page">
      <div  className="main-MF"
        style={{
          backgroundImage: `url(${Team})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "150px",
          textAlign: "center",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div>
          <NavLink2 />
          <h1>Our Team</h1>
        </div>
        
      </div>
      <div className="team-page-card1">
        <button className="team-page-btn1">
          {" "}
          <Link to="/ourteam" className="team-links">
            Management Team
          </Link>
        </button>
        <button className="team-page-btn2">
          <Link to="/advisorycommitee" className="team-links2">
            Advisory Committee of Experts
          </Link>
        </button>
      </div>
      <div className="team-page-card2">
        <h2
          style={{
            textAlign: "",
            color: "#044188",
         
          }}
        >
          Advisory Committee of Experts
        </h2>
        <Container style={{ width: "60%" }} className="mx-auto p-2" >
          <Row className="mt-2 mx-auto p-2">
            <Col className="m-0">
              {" "}
              <div className="profile-box">
                <img src={Profile12} alt="" className="profile-img" />
                <Button variant="link" onClick={() => setModalShow1(true)}>
                  <h4>Lateef Onireti Ibraheem</h4>
                </Button>

                <MyVerticallyCenteredModal1
                  show={modalShow1}
                  onHide={() => setModalShow1(false)}
                />
                <p>Chairman​</p>
              </div>
            </Col>
            <Col className="m-0">
              <div className="profile-box">
                <img src={Profile13} alt="" className="profile-img" />
                <Button variant="link" onClick={() => setModalShow2(true)}>
                  <h4>Abdulqadir Ibrahim Abikan</h4>
                </Button>

                <MyVerticallyCenteredModal2
                  show={modalShow2}
                  onHide={() => setModalShow2(false)}
                />
                <p>Member</p>
              </div>
            </Col>
          </Row>
          <Row className="mx-auto p-2">
            <Col className="">
              {" "}
              <div className="profile-box">
                <img src={Profile14} alt="" className="profile-img" />
                <Button variant="link" onClick={() => setModalShow3(true)}>
                  <h4>Muritala Kewuyemi Kareem</h4>
                </Button>
                <MyVerticallyCenteredModal3
                  show={modalShow3}
                  onHide={() => setModalShow3(false)}
                />
                <p>Member​</p>
              </div>
            </Col>
            <Col className="">
              <div className="profile-box">
                <img src={Profile15} alt="" className="profile-img" />
                <Button variant="link" onClick={() => setModalShow4(true)}>
                  <h4>Omotayo Wright</h4>
                </Button>
                <MyVerticallyCenteredModal4
                  show={modalShow4}
                  onHide={() => setModalShow4(false)}
                />
                <p>Member</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Footer5 />
      </div>
      <div className="footer-4">
        <Footer4 />
      </div>
    </div>
  );
}

export default AdvisorycommiteePage;
