import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AboutPage from "./pages/AboutPage";
import TeamPage from "./pages/TeamPage";
import ContactPage from "./pages/ContactPage";
import ServicesPage from "./pages/ServicesPage";
import WealthManagementPage from "./pages/WealthManagementPage";
import ResearchPage from "./pages/ResearchPage";
import EthicalInvestmentPage from "./pages/EthicalInvestmentPage";
import PortfolioManagementPage from "./pages/PortfolioManagementPage";
import AdvisorycommiteePage from "./pages/AdvisorycommiteePage";
import MainMutualFundPage from "./pages/MainMutualFundPage";
import ReportPage from "./pages/ReportPage";
import EuroBond from "./pages/EuroBond";
import WealthManagementproducts from "./pages/WealthManagementproducts";
import AmBackend from "./pages/AmBackend";
import AmUpfront from "./pages/AmUpfront";
import TrustbancKids from "./pages/TrustbancKids";
import { DollarProduct } from "./pages/DollarProduct";
import MudarabaDeposits from "./pages/MudarabaDeposits";
import MudarabaDebositFcy from "./pages/MudarabaDebositFcy";
import RestrictedMudaraba from "./pages/RestrictedMudaraba";
import Thankyou from "./pages/Thankyou";
import Request from "./pages/Request";
// import ReportUpdate from "./pages/ReportUpdate";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
  },
  {
    path: "/services",
    element: <ServicesPage />,
  },
  {
    path: "/ourteam",
    element: <TeamPage />,
  },
 
  {
    path: "/ethicalinvestment",
    element: <EthicalInvestmentPage />,
  },
  {
    path: "/mutualfund",
    element: <MainMutualFundPage />,
  },
  {
    path: "/advisorycommitee",
    element: <AdvisorycommiteePage />,
  },

  {
    path: "/portfoliomanagement",
    element: <PortfolioManagementPage />,
  },
  {
    path: "/wealthmanagement",
    element: <WealthManagementPage />,
  },
  {
    path: "/research",
    element: <ResearchPage />,
  },
  {
    path: "/report",
    element: <ReportPage />,
  },
  {
    path: "/make_a_request",
    element: <Request />,
  },
  {
    path: "/thankyou",
    element: <Thankyou />,
  },
  // {
  //   path: "/report-update",
  //   element: <ReportUpdate />,
  // },
  {
    path: "/product1",
    element: <WealthManagementproducts />,
  },
  {
    path: "/product2",
    element: <AmBackend />,
  },
  {
    path: "/product3",
    element: <AmUpfront />,
  },
  {
    path: "/product4",
    element: <DollarProduct />,
  },
  {
    path: "/product5",
    element: <TrustbancKids />,
  },
  {
    path: "/product6",
    element: <EuroBond />,
  },
  {
    path: "/unrestricted-mudaraba-deposit",
    element: <MudarabaDeposits />,
  },
{path: "/unrestricted-mudaraba-deposit-fcy",
  element:<MudarabaDebositFcy/>,
},
{path: "/restricted-mudaraba-deposit",
element:<RestrictedMudaraba/>},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
