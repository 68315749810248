import React from "react";
import NavLink2 from "../compnents/NavLink2";
import Image97 from "../images/image 97.png";
import Image66 from "../images/image 66.png";
import Image67 from "../images/image 67.png";
import Maskgroup4 from "../images/Mask group4.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Footer5 from "../compnents/Footer5";
import Ethical from "../images/Group332.png";
import Footer4 from "../compnents/Footer4";

function MudarabaDebositFcy() {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${Ethical})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "400px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div>
          <NavLink2 />
        </div>
        <div
          style={{
            textAlign: "center",
            color: "white",
            position: "relative",
            top: "100px",
          }}
        >
          <h3
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "50px",
              lineHeight: " 75px",
              color: " white",
            }}
          >
            Ethical Investment
          </h3>
        </div>
      </div>
      <div className="mutual-cards">
        {" "}
        <h4>Unrestricted Mudaraba Deposit FCY</h4>
        <div className="mutual-card1">
          <img src={Image66} alt="" className="mutual-img" />
          <div className="mutual-info">
            <p>
              Unrestricted Mudaraba Deposit FCY is a US dollar investment
              product that allows customers to invest FCY funds with TrustBanc
              Asset Management with the objective of earning profits over a
              period of time. The product operates on the principles of
              Mudaraba, wherein the customer is the capital provider (Robbul
              Maal) while TrustBanc as the entrepreneur (Mudarib) invests the
              funds in Shariah-compliant assets.
              <br />
              <ul>
              <b>Benefits to Investors</b>
                <br />
                <li>Competitive and attractive returns on investment.</li>
                <li>
                  Opportunity to earn above the expected profit in the event the
                  Bank returns more profit on its assets.
                </li>
                <li>Alternative investment to the Eurobond markets. </li>
                <li>Investment in a short-medium term product. </li>
                <li>Investment in Shariah-compliant products.</li>
                <li>No management fees.</li>
                <li>Capital preservation.</li>
                <li>Portfolio diversification.</li>
              </ul>
              <br />
              <ul>
              <b>Features</b>
                <br />
                <li>Minimum investment amount of US$5,000.</li>
                <li>Minimum tenor of 180 days.</li>
                <li>
                  Profit shall be distributed upon the maturity of the Mudaraba
                  or at an agreed period.
                </li>
                <li>
                  Funds are pooled for investment in low-risk shariah-compliant
                  assets with maximum return on investment.
                </li>
                <li>Investment assets shall be sanctioned by the ACE.</li>
                <li>Pre-termination/liquidation shall be allowed.</li>
              </ul>
              <ul>
               <b> Target investor</b>
                <br />
                <li>Retail</li>
                <li>Ultra-High Net worth Individuals (UHNIs).</li>
                <li>High Net worth Individuals (HNIs).</li>
                <li>Institutional clients.</li>
              </ul>
            </p>
            <Link to="/contact" className="contact-link">
              Contact Us
            </Link>
          </div>
        </div>
      </div>{" "}
      <Container className="service-grid">
        <Row
          className="justify-content-md-center"data-aos="zoom-in-up"
          style={{ marginTop: "100px" }}
        >
          {" "}
          <h3 style={{ fontSize: "20px", marginLeft: "170px" }}>
            Ethical Investments
          </h3>
          <Col className="service-cards" md="auto">
            {" "}
            <h1 style={{}}>Unrestricted Mudaraba Deposits</h1>
            <div className="service-card1">
              <img src={Image97} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  Unrestricted Mudaraba Deposit is an investment product that
                  allows customers to invest funds with TrustBanc Asset
                  Management with the objective of earning profits over a period
                  of time.
                  <button className="service-btn">
                    <Link
                      to="/unrestricted-mudaraba-deposit"
                      className="service-link"
                    >
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>
          <Col className="service-cards" md="auto">
            <h1 style={{}}>Restricted Mudaraba Deposit</h1>
            <div className="service-card1">
              <img src={Image67} alt="" className="service-img2" />
              <div className="service-info">
                <p>
                  Restricted Mudaraba Deposit is a deposit account with
                  investment features for customers to invest funds with
                  TrustBanc Asset Management......
                  <button className="service-btn">
                    <Link
                      to="/restricted-mudaraba-deposit"
                      className="service-link"
                    >
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt="" style={{ width: "20px" }} />
                  </button>
                </p>
              </div>
            </div>
          </Col>
        </Row>
     
      </Container>
      <div>
        <Footer5 />
      </div>
      <div className="footer-4">
      <Footer4 /></div>
    </div>
  );
}

export default MudarabaDebositFcy;
