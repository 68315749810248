import React from "react";
import Call from "../images/call.png";
import Linkedin from "../images/linkedin.png";
import Facebook from "../images/facebook.png";
import Twitter from "../images/twitter2.png";
import Instagram from "../images/instagram2.png";
import Mail from "../images/mail.png";
// import TweetEmbed from "react-tweet-embed";
// import { TwitterTimelineEmbed } from "react-twitter-embed";

const Footer4 = () => {
  return (
    <div className="footer4-cards">
      <div className="footer4-card1">
        <h5>HEAD OFFICE ADDRESS</h5>
        <p>
          163, Sinari Daranijo Street, Off Ligali Ayorinde, Victoria Island,
          Lagos, Nigeria.{" "}
        </p>
        <h5>ABUJA OFFICE</h5>
        <p>
          No 6, Brains and Hammers Estate, Apo 3, Ado Bayero, Abuja, Nigeria{" "}
        </p>
        <h5>CONTACT US</h5>
        <p>07004446147 support@trustbancgroup.com</p>
        <div className="social-media-icon">
          <a href="tel:07004446147" target="_blank" rel="noopener noreferrer">
            <img src={Call} alt="" className="social-icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/70898742/admin/?lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3BL6wam5krRz2uao0Sm7I3DA%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Linkedin} alt="" className="social-icon" />
          </a>
          <a
            href="https://www.facebook.com/trustbancfinancialgroup?mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Facebook} alt="" className="social-icon" />
          </a>
          <a
            href="https://twitter.com/trustbancgroup?s=21&t=ZHjrl1jeMADQ2febfriVOw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Twitter} alt="" className="social-icon" />
          </a>
          <a
            href="https://instagram.com/trustbancfinancialgroup?igshid=YmMyMTA2M2Y="
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Instagram} alt="" className="social-icon" />
          </a>
          <a
            href="https://mail.google.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <img src={Mail} alt="" className="social-icon" />
          </a>{" "}
        </div>
      </div>
      <div className="footer4-card2">
        <h5>RECENT TWEET</h5>
        <div className="tweet-box">
          <a
            className="twitter-timeline"
            data-width="100%"
            data-height="330"
            href="https://twitter.com/TrustBancgroup?ref_src=twsrc%5Etfw"
          >
            Tweets by TrustBancgroup
          </a>
        </div>
      </div>
      <div className="footer4-card3">
        <h5>SUBSCRIBE TO OUR NEWSLETTER</h5>

        <form
          className="subscribe-box"
          action="https://formsubmit.co/support@trustbancgroup.com"
          method="POST"
        >
          <input
            type="hidden"
            name="_next"
            value="
            https://trustbancasset.com/thankyou"
          />
          <input type="hidden" name="_captcha" value="false"></input>{" "}
          <label>Name</label>
          <input type="text" placeholder="" name="name" required />
          <label>Email</label>
          <input type="email" placeholder="" name="email" required />
          <p>
            By signing up, you agree to receive news, products offers and other
            commercial messages delivered to your inbox.
            <br />
            <br />
            T&C applies.
          </p>
          <button className="subscribe-box-btn">Subscribe</button>
        </form>
      </div>
    </div>
  );
};

export default Footer4;
