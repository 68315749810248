import React from "react";
import Logo from "../images/logo.png";
import { Link } from "react-router-dom";

function Footer3() {
  return (
    <div className="footer3-holder">
      <div className="footer3-card1">
        <img src={Logo} className="logo" alt="logo" />
      </div>
      <div className="footer3-card-holder">
        <div className="footer3-card2">
          <h5>Features</h5>
          <p>
            <Link to="/about" className="footer3-link">
              About{" "}
            </Link>
          </p>

          <p>
            {" "}
            <Link to="/services" className="footer3-link">
              Service{" "}
            </Link>
          </p>
          <p>
            {" "}
            <Link to="/contact" className="footer3-link">
              Contact{" "}
            </Link>
          </p>
        </div>
        <div className="footer3-card3">
          <h5>Group and Subsidiaries</h5>
          <a href="https://trustbancgroup.com/"target="_blank" rel="noreferrer">
            <p>TrustBanc Holdings</p>
          </a>
          <a href="https://trustbancmfb.com/"target="_blank" rel="noreferrer">
            <p>TrustBanc MFB</p>
          </a>
          <a href="https://trustbanccapital.com/"target="_blank" rel="noreferrer">
            <p>TrustBanc Capital Management Limited</p>
          </a>
          <a href="https://wefinanceng.com/" target="_blank" rel="noreferrer">
            <p>WeFinance Solution Limited.</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer3;
