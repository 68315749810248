import React from "react";
import "../thankyou.css";

function Thankyou() {
  return <div> 
    <div class="container">
  <div class="col">
      <div class="col-md-8 offset-md-2">
          <h1>Thank You</h1>
          <p>This form has been submited <a href="/"> Click Here</a> to go back to homepage</p>
                      </div>
  </div> </div>
\  </div>;
}

export default Thankyou;
