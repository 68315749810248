import React from "react";
import { Link } from "react-router-dom";

const Footer5 = () => {
  return (
    <div className="footer5-cards">
      <h4>Copyright © {new Date().getFullYear()} - TrustBanc Asset Management</h4>
      <div className="footer-links-holder">
        <Link to="/about" className="footer-links">
          <p>About</p>
        </Link>
        <Link to="/services" className="footer-links">
          <p>Product & Services</p>
        </Link>
        <Link to="/contact" className="footer-links">
          <p>Contact</p>
        </Link>
      </div>
    </div>
  );
};

export default Footer5;
