import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "../Request.css";
import CloseIcon from "@mui/icons-material/Close";
import Form from "react-bootstrap/Form";

const PaymentForm = ({ setShowDiv }) => {
  // condition to show/hide forms
 
  //////payment form  /////////////////
  const [formData, setFormData] = useState({
    accountname: "",
    email: "",
    phonenumber: "",
    producttype: "",
    recipient: {
      amount: "",
      usecustomerdetail: ""
    }
  });

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    // Handle checkbox separately
    if (type === "checkbox") {
      setFormData({
        ...formData,
        recipient: {
          ...formData.recipient,
          [name]: checked // Update with checked value (true/false)
        }
      });
    } else {
      // For other input types
      if (name === "amount") {
        setFormData({
          ...formData,
          recipient: {
            ...formData.recipient,
            [name]: value
          }
        });
      } else {
        setFormData({
          ...formData,
          [name]: value
        });
      }
    }
  };
  // api for payment form
  const apiUrlPayment =
    "https://tptest.trustbancgroup.com:4443/MFB_USSD/api/v1/asset/paymentrequest";
  //
  const handlePayment = (e) => {
    e.preventDefault();
    axios
      .post(apiUrlPayment, formData)
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Thank You!",
            text: response.data.responsemessage
          });
          setTimeout(() => {
            window.location.reload(); // Reload the page after a delay
          }, 4000); // Adjust timeout delay as needed (5000 milliseconds = 4 seconds)
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: response.data.responsemessage,
            showConfirmButton: true
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          // Server responded with an error status code

          Swal.fire({
            icon: "error",
            title: "Oops",
            text: error.response.data.message,
            showConfirmButton: true
          });
        } else {
          // Request was made but no response received or network error occurred

          Swal.fire({
            icon: "error",
            title: "Oops",
            text: "An error occurred. Please try again later.",
            showConfirmButton: true
          });
        }
      });
  };
  return (
    <div className="form-container">
      <button
        onClick={() => {
          setShowDiv(true);
        }}
        className="close-btn"
      >
        <CloseIcon style={{ fontSize: "35px" }} />
      </button>
      <div className="form-card">
        <Form className="request-form" onSubmit={handlePayment}>
          <h5
            style={{
              color: "#044188",
              fontSize: "16px",
              fontWeight: "600"
            }}
          >
            Payment/Redemption
          </h5>
          <Form.Group className="mb-2" controlId="formGroupEmail">
            <Form.Label>Account Name</Form.Label>
            <Form.Control
              type="text"
              name="accountname"
              value={formData.accountname}
              placeholder=""
              onChange={handleChange}
              style={{ outline: "none" }}
              required
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="formGroupEmail">
            <Form.Label>Email </Form.Label>
            <Form.Control
              type="email"
              placeholder=""
              name="email"
              required
              value={formData.email}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="formGroupEmail">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder=""
              required
              value={formData.phonenumber}
              name="phonenumber"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="formGroupEmail">
            <Form.Label>Choose Product</Form.Label>
            <Form.Select
              aria-label=""
              name="producttype"
              required
              value={formData.producttype}
              onChange={handleChange}
            >
              <option>Select Product Type</option>
              <option value="TRUSTBANC MONEY MARKET FUND">
                TRUSTBANC MONEY MARKET FUND
              </option>
              <option value="HOLDCO. CONVERTIBLE NOTES">
                HOLDCO. CONVERTIBLE NOTES
              </option>
              <option value="HOLD CO DISCOUNTED NOTES">
                HOLD CO DISCOUNTED NOTES
              </option>
              <option value="TBAM AMLNOTE (UPPFRONT)">
              TrustBanc AM AMLNOTE (UPPFRONT)
              </option>
              <option value="TBAM AML NOTE (BACKEND)">
              TrustBanc AM AML NOTE (BACKEND)                                                                                                                                                                                                            AML NOTE (BACKEND)
              </option>
              <option
                value=" TRUSTBANC ETHICAL INCOME NOTE_UNRESTRICTED MUDARABAH
            CONTRACT"
              >
                TRUSTBANC ETHICAL INCOME NOTE_UNRESTRICTED MUDARABAH CONTRACT
              </option>
              <option value="TRUSTBANC FIXED INCOME PORTFOLIO">
                TRUSTBANC FIXED INCOME PORTFOLIO
              </option>
              <option value="TRUSTBANC HOLDCO NOTES">
                TRUSTBANC HOLDCO NOTES
              </option>
              <option value="OTHER INVESTMENTS"> OTHER INVESTMENTS</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-2" controlId="formGroupEmail">
            <Form.Label>Enter Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="₦"
              required
              name="amount"
              value={formData.recipient.amount}
              onChange={handleChange}
            />
          </Form.Group>
          <div className="">
            <input
              className="form-check-input"
              type="checkbox"
              required
              onChange={handleChange}
              value={formData.recipient.usecustomerdetail}
              name="usecustomerdetail"
            />
            <label className="form-check-label">
              Use Bank Details On My Account
            </label>
          </div>
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </Form>
      </div>
    </div>
  );
};

export default PaymentForm;
