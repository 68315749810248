import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Right from "../images/right-arrow.png";

const Accordions = () => {
  return (
    <div>
      {" "}
      <div className="about-page-card4">
        <h1 className="fs-2 text-center">Frequently asked questions</h1>
        <div className="accordion1">
        <div className="accordion-body mx-auto p-2">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Who is TrustBanc Asset Management Limited?
              </Accordion.Header>
              <Accordion.Body>
                TrustBanc Asset management is a licensed financial service
                provider covering Fund and portfolio management services.
                <br />
              <br />
                It is licensed and regulated by the
                Securities and Exchange Commission (SEC) which delivers a broad
                range of financial solutions and products to meet the unique
                needs of its clients.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {" "}
                What are the available Investment Products TrustBanc Asset
                management offers?
              </Accordion.Header>
              <Accordion.Body>
                <h5 class="fs-6">Mutual Funds</h5>
                <p>
                  {" "}
                  <img src={Right} alt="" style={{ width: "20px" }} /> TrustBanc
                  Money Market Fund
                </p>
                <h5 class="fs-6">Private Portfolio Products</h5>
                <p>
                  {" "}
                  <img
                    src={Right}
                    alt=""
                    style={{ width: "20px", marginRight: "5px" }}
                  />
                  Shariah Complaint/EthicalInvestment
                </p>
                <p>
                  {" "}
                  <img src={Right} alt="" style={{ width: "20px" }} /> Other Private Portfolio Products
                </p>
                Please contact{" "}
                <a href="mailto:sales@trustbancgroup.com">
                  sales@trustbancgroup.com
                </a>{" "}
                for other private portfolio products
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                What is the minimum investment value for each product?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  {" "}
                  <img src={Right} alt="" style={{ width: "20px" }} />
                  TrustBanc Money Market Fund – Minimum subscription is N5,000
                </p>
                Please contact{" "}
                <a href="mailto:sales@trustbancgroup.com">
                  sales@trustbancgroup.com
                </a>{" "}
                for more information on other products
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                How can I keep track of my investment portfolio?
              </Accordion.Header>
              <Accordion.Body>
                Information regarding your investment is available 24/7 on our
                client’s portal. An up-to-date performance of your investment
                portfolio is also sent via email along side your weekly
                statements detailing your portfolio, asset allocation, and
                performance summary.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                What are the requirements for opening an account? How long does
                it take?
              </Accordion.Header>
              <Accordion.Body>
                To open an investment account seamlessly, please click the link
                below; <br/>
                <a href="https://cp-trustbanc.zanibal.com/#/open-account">
                  https://cp-trustbanc.zanibal.com/#/open-account
                </a>
               <br/> You can also send an email to;{" "}
               <br/> <a href="mailto:sales@trustbancgroup.com">
                  sales@trustbancgroup.com
                </a>{" "}
                one of our relation manager will contact you.
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            </div>
           <div className="accordion-body mx-auto p-2">
            <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                How do I fund my account for investment purposes?
              </Accordion.Header>
              <Accordion.Body>
                You can proceed to fund your account by effecting a direct
                transfer into the designated Portfolio nominee account you wish
                to Invest in, using your TrustBanc account reference number and
                name as the narration. Payment received after 2 p.m. will be
                applied into your account the next working day. Contact{" "}
                <a href="mailto:sales@trustbancgroup.com">
                  sales@trustbancgroup.com
                </a>{" "}
                for more Information.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                How long does it take to redeem my investment?
              </Accordion.Header>
              <Accordion.Body>
                Redemption requests are processed within 48 hours.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                Is my investment secured with TrustBanc Asset Management?
              </Accordion.Header>
              <Accordion.Body>
                Yes. TrustBanc Asset management is Licensed and regulated by the
                Securities and Exchange Commission (SEC) to offer fund and
                portfolio manager services. 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                For more enquires, how do I reach out?
              </Accordion.Header>
              <Accordion.Body>
                Contact us via email{" "}
                <a href="mailto:sales@trustbancgroup.com">
                  support@trustbancgroup.com
                </a>{" "}
                or  <br/>Call Support - 07004446147
                <br />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion></div>
        </div>
      </div>
    </div>
  );
};

export default Accordions;
