import React from "react";

const Statement = ({ setShowDiv2 }) => {
  return (
    <div className="request-card">
      <div className="payment-overlay">
        <p className="px-5">
          Make requests for your valuation statement, Reference letters etc. and
          we will respond within 24 working hours.
        </p>
        <button
          className="request-btn"
          onClick={() => {
            setShowDiv2(false);
          }}
        >
          Request
        </button>
      </div>
    </div>
  );
};

export default Statement;
