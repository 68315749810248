import React, { useEffect } from "react";
import Piechart from "../images/piechart.png";
import Papercut from "../images/papercut.png";
import Coin from "../images/coin.png";
import Eyes from "../images/eyes.png";
import Universal from "../images/universal.png";
import NavLink2 from "../compnents/NavLink2";
import Footer5 from "../compnents/Footer5";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Footer4 from "../compnents/Footer4";
import Accordions from "../compnents/Accordions";

function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="about-page">
      <div className="about-page-header">
        <NavLink2 />
        <h1>About TrustBanc AM</h1>
      </div>
      {/* AboutPage cards */}
      <div className="about-page-cards">
        <div style={{ textAlign: "center" }}>
          <h3 className="fw-bold text-dark">About us</h3>
        </div>
        <div className="about-page-card1 ">
          <div className="about-page-card1-text">
            <p>
              TrustBanc Asset Management Limited (“TrustBanc AM”) is licensed
              and regulated by the SEC as a funds and portfolio manager.
              TrustBanc AM was established in 2014 through the acquisition of
              Bridge Point Asset Management Limited. The acquisition was a great
              opportunity for the Group to operate Wealth Management and
              Portfolio Management services. The Company has now grown to become
              one of the biggest fund managers in the market.TrustBanc Asset
              Management delivers a broad range of financial solutions and
              products to meet the unique needs of its clients.
            </p>
          </div>
          <div className="about-page-card1-img">
            <img className="about-img" src={Piechart} alt="" loading="lazy" />
          </div>
        </div>
        <div
          className="about-page-card1"
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          data-aos-duration="400"
        >
          <div className="about-page-card1-text">
            <h3>
              We are true to ourselves, and commit to always perform at our
              best.
            </h3>
            <p>
              We believe that we are outstanding. Not because we say it, but
              because we work hard at it. We are dedicated, committed and
              focused. We believe that every person will reach their personal
              best and overcome any challenge through a shared culture and
              ethos.
            </p>
          </div>
          <div className="about-page-card1-img">
            <img className="about-img" src={Papercut} alt="" loading="lazy" />
          </div>
        </div>
        <div className="about-page-card2">
          <div className="about-page-card2-text">
            <div className="about-page-card2-box">
              {" "}
              <h3>What we stand for</h3>
              <div className="logo-box">
                <div>
                  {" "}
                  <img
                    src={Coin}
                    loading="lazy"
                    alt=""
                    style={{ width: "50px", marginRight: "10px" }}
                  />
                </div>
                <div>
                  <h4>MISSION STATEMENT</h4>
                  <p>“Your lifetime companion for sustainable wealth”</p>
                </div>{" "}
              </div>
              <div className="logo-box">
                <div>
                  <img
                    src={Eyes}
                    loading="lazy"
                    alt=""
                    style={{ width: "50px", marginRight: "10px" }}
                  />
                </div>
                <div>
                  <h4>VISION STATEMENT</h4>
                  <p>
                    “To be the preferred provider of seamless integrated
                    financial solutions to our clients”
                  </p>{" "}
                </div>
              </div>
              <div className="logo-box">
                <div>
                  <img
                    src={Universal}
                    loading="lazy"
                    alt=""
                    style={{ width: "50px", marginRight: "10px" }}
                  />
                </div>
                <div>
                  {" "}
                  <h4>OUR CORE VALUES</h4>
                  <p>E – Empathy</p>
                  <p>A – Accountability</p>
                  <p>S – Service Orientation </p>
                  <p>E – Ethics</p>{" "}
                </div>
              </div>
            </div>
            <div className="about-page-card2-box2">
              <h3>Why choose us</h3>
              <h5 className="fw-normal">OUR GROWTH AND RATINGS</h5>
              <p>
                In the past four years, we have expanded by leaps and bounds to
                rank among the top fund managers in the industry. We are rated "
                A+" by DataPro with a stable outlook and an upward trend.
              </p>

              <h5 className="fw-normal">OUR CLIENT AND PARTNERS</h5>
              <p>
                Over the years, TrustBanc Asset Management has formed
                significant partnerships in the financial and non-financial
                sectors, significantly increasing its asset under management and
                putting it on track to rank among the top in the industry.
              </p>
            </div>
          </div>
        </div>
        <div
          className="about-page-card3"
          style={{
            backgroundImage:
              "linear-gradient(92.84deg, #83460F 45.22%, #D07F31 82.47%, #C8721F 98.11%)",
            color: "#ffff"
          }}
        >
          {" "}
          <AnimationOnScroll
            animateIn="animate__fadeInLeftBig"
            duration={1}
            playState=""
          >
            <h1>
              We are true to ourselves, and commit to <br />
              always perform at our best.
            </h1>
          </AnimationOnScroll>
        </div>
        <div className="accordion-card">
          <Accordions />
        </div>
      </div>

      <div>
        <Footer5 />
      </div>
      <div className="footer-4">
        <Footer4 />
      </div>
    </div>
  );
}

export default AboutPage;
