import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../images/logo.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

function NavLink() {
  const [showDropdown1, setShowDropdown1] = useState(false);

  const handleDropdownMouseEnter1 = () => {
    setShowDropdown1(true);
  };

  const handleDropdownMouseLeave1 = () => {
    setShowDropdown1(false);
  };
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleDropdownMouseLeave = () => {
    setShowDropdown(false);
  };
  return (
    <div>
      <Navbar expand="lg" id="navbar">
        <Container fluid>
          <Navbar.Brand>
            {" "}<Link to="/" className="links">
              {" "}<img src={Logo} className="logo" alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-end mobile-nav">
            <Nav>
              <NavDropdown
                title="About"
                className="nav-dropdown"
                show={showDropdown}
                onMouseEnter={handleDropdownMouseEnter}
                onMouseLeave={handleDropdownMouseLeave}
              >
                <NavDropdown.Item>
                  <Link to="/about" className="links">
                    {" "}About TrustBanc AM
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/ourteam" className="links">
                    Our Team
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Product & Services"
                className="nav-dropdown"
                show={showDropdown1}
                onMouseEnter={handleDropdownMouseEnter1}
                onMouseLeave={handleDropdownMouseLeave1}
              >
                <NavDropdown.Item>
                  <Link to="/services" className="links">
                    Mutual Funds
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/wealthmanagement" className="links">
                    Wealth Management
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/portfoliomanagement" className="links">
                    Portfolio Management
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/research" className="links">
                    Research
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/ethicalinvestment" className="links">
                    Ethical Investment
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <Button
                style={{ border: "none" }}
                target="_blank"
                data-aos="zoom-in"
                data-asos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
              <a href="https://cp-trustbanc.zanibal.com/#/open-account" target="_blank" className="links text-light" without rel="noreferrer">
  Invest With Us
</a> 
              </Button>
              <LinkContainer to="/make_a_request">
                <Button
                  style={{ border: "none" }}
                  data-aos="zoom-in"
                  data-asos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                >
                  Payment/Request
                </Button>
              </LinkContainer>

              <Link to="/contact">
                <button className="nav-btn">Contact us</button>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavLink;
