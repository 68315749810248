import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Logo from "../images/whitelogo.png";
import Container from "react-bootstrap/Container";

function NavLink2() {

  const [showDropdown1, setShowDropdown1] = useState(false);

  const handleDropdownMouseEnter1 = () => {
    setShowDropdown1(true);
  };

  const handleDropdownMouseLeave1 = () => {
    setShowDropdown1(false);
  };
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleDropdownMouseLeave = () => {
    setShowDropdown(false);
  };
  return (
    <div className="navlink2">
      <Navbar expand="lg">
        <Container fluid>
          <Navbar.Brand >
            {" "}
            <Link to="/" className="links">
              {" "}
              <img src={Logo} className="logo" alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-end mobile-nav">
            <Nav>
            <Nav.Link to="/"   id="nav-dropdown2">Home</Nav.Link>

              <NavDropdown
                title="About"
                id="nav-dropdown2"
                show={showDropdown}
                // onClick={()=> setShowDropdown(!showDropdown)}
                onMouseEnter={handleDropdownMouseEnter}
                onMouseLeave={handleDropdownMouseLeave}
              >
                <NavDropdown.Item>
                  <Link to="/about" className="links">
                    {" "}
                    About TrustBanc AM
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/ourteam" className="links">
                    Our Team
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Product & Services"
                id="nav-dropdown2"
                show={showDropdown1}
                // onClick={()=> setShowDropdown1(!showDropdown1)}
                onMouseEnter={handleDropdownMouseEnter1}
                onMouseLeave={handleDropdownMouseLeave1}
              >
                <NavDropdown.Item>
                  <Link to="/mutualfund" className="links">
                    Mutual Funds
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/wealthmanagement" className="links">
                    Wealth Management
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/portfoliomanagement" className="links">
                    Portfolio Management
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/research" className="links">
                    Research
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/ethicalinvestment" className="links">
                    Ethical Investment
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <Button style={{ backgroundColor: "#ffff", color: "blue" }}>
                <Link to="/contact" className="links">
                  Contact us
                </Link>
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavLink2;
