import React from "react";
import image from "../images/image15.png";
import Shuffle from "../images/shuffle.png";
import Boardarrow from "../images/boardarrow.png";
import Checkbook from "../images/checkbook.png";

function Attribution() {
  return (
    <div
      style={{ backgroundImage: `url(${image})` }}
      className="attribution-container"
    >
      <h4>Attributes</h4>
      <div className="attribution-card-holder">
        <div
          className="attribution-card"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <img src={Shuffle} style={{ width: "50px" }} alt="" loading="lazy" />
          <h3>Competitive Returns</h3>
          <p>
            Our team of expert managers employ effective strategies to ensure
            your money optimally work for you.
          </p>
        </div>
        <div
          className="attribution-card"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <img
            src={Boardarrow}
            style={{ width: "50px" }}
            alt=""
            loading="lazy"
          />

          <h3>Service Oriented</h3>
          <p>
            We focused on providing individualized service as we consciously
            work to fulfil your unique investment needs.
          </p>
        </div>
        <div
          className="attribution-card"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <img
            src={Checkbook}
            style={{ width: "50px" }}
            alt=""
            loading="lazy"
          />

          <h3>Accountability</h3>
          <p>
            We are positively accountable to our clients and partners through
            the use of innovative tools.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Attribution;
