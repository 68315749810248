import React, { useEffect } from "react";
import Phone from "../images/phone.png";
import NavLink2 from "../compnents/NavLink2";
import Icon1 from "../images/Group 346.png";
import Linkedin from "../images/linkedin.png";
import Facebook from "../images/facebook.png";
import Twitter from "../images/twitter2.png";
import Instagram from "../images/instagram2.png";
import Mail from "../images/mail.png";
import Icon2 from "../images/Group 347.png";
import Icon3 from "../images/Group 348.png";
import Accordions from "../compnents/Accordions";
import Footer5 from "../compnents/Footer5";
import Footer4 from "../compnents/Footer4";

function ContactPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="contact-page">
      <div className="main-MF"
        style={{
          backgroundImage: `url(${Phone})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          textAlign: "center",
          height: "150px",
        }}
      >
        <div>
          <NavLink2 />
          <h1>Contact</h1>
        </div>
        
      </div>
      <div className="contact-holder">
        <div className="contact-icon">
          <a href="https://www.linkedin.com/company/trustbanc-financial-group/mycompany/"target="_blank"rel="noreferrer"> 
            <img src={Linkedin} alt="" className="social-icon" />
          </a>
          <a href="https://www.facebook.com/trustbancfinancialgroup?mibextid=LQQJ4d"target="_blank"rel="noreferrer">
            <img src={Facebook} alt="" className="social-icon" />
          </a>
          <a href="https://twitter.com/trustbancgroup?s=21&t=ZHjrl1jeMADQ2febfriVOw"target="_blank" rel="noreferrer">
            <img src={Twitter} alt="" className="social-icon" />
          </a>
          <a href="https://instagram.com/trustbancfinancialgroup?igshid=YmMyMTA2M2Y="target="_blank"rel="noreferrer">
            <img src={Instagram} alt="" className="social-icon" />
          </a>
          <a href="mailto:support@trustbancgroup.com"target="_blank"rel="noreferrer">
            {" "}
            <img src={Mail} alt="" className="social-icon" />
          </a>{" "}
        </div>
        
        <div className="contact-page-card1">
          <div className="contact-page-form d-flex justify-content-center">
            <form
              action="https://formsubmit.co/support@trustbancgroup.com"
              method="POST"
            >
              <input type="hidden" name="_next" value="
https://trustbancasset.com/thankyou"/>

              <h4>Send a message</h4>
             
                <input type="hidden" name="_captcha" value="false"></input>
              <input type="text" placeholder="Your name" name="name" required />
             
              <input
                type="email"
                placeholder="Your email"
                name="email"
                required
              />
              <input
                type="text"
                placeholder="Subject"
                name="subject"
                required
              />
            
              <textarea
                placeholder="Your message"
                rows="4" cols="50"
                name="message"
                style={{ outline: "none" }}
                required
              ></textarea>
              <button className="contact-btn" type="submit">
                send
              </button>
            </form>
          </div>
          <div className="contact-page-info d-flex justify-content-center">
            <div className="info-text-holder">
              <h4>Get in touch</h4>
              <div className="contact-page-info-text">
                <div>
                  <img src={Icon1} alt="" style={{ width: "40px" }} />
                </div>
                <p style={{ marginLeft: "20px" }}>
                  163 Sinari Daranijo Street, Off Ligali Ayorinde, Victoria
                  Island, Lagos.
                </p>
              </div>
              <div className="contact-page-info-text">
                <div>
                  <img src={Icon2} alt="" style={{ width: "40px" }} />
                </div>

                <p style={{ marginLeft: "20px" }}>
                  (+234) 700 444 6147
                  <br />
                  <span style={{ fontSize: "12px" }}>
                    MON-FRI , 8:00AM - 5:00PM
                  </span>
                </p>
              </div>
              <div className="contact-page-info-text">
                <div>
                  <img src={Icon3} alt="" style={{ width: "40px" }} />
                </div>

                <p style={{ marginLeft: "20px" }}>
                  support@trustbancgroup.com
                  <br />{" "}
                  <span style={{ fontSize: "12px" }}>
                    WE REPLY WITHIN 24 HRS
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-card">
          <Accordions />
        </div>
      </div>{" "}
      <>
        <Footer5 />
      </>
      <div className="footer-4">
      <Footer4 /></div>
    </div>
  );
}

export default ContactPage;
