import React, { useEffect } from "react";
import Image64 from "../images/image 64.png";
import NavLink2 from "../compnents/NavLink2";
import Pin from "../images/Group337.png";
import Image18 from "../images/image 18-1.png";
import Maskgroup4 from "../images/Mask group4.png";
import Footer4 from "../compnents/Footer4";
import Image17 from "../images/image 17-1.png";
import Image19 from "../images/image 19-1.png";
import Image47 from "../images/image 47.png";
import { Link } from "react-router-dom";
import Right from "../images/right-arrow.png";
import Footer5 from "../compnents/Footer5";

function MainMutualFundPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <div className="main-MF"
        style={{
          backgroundImage: `url(${Pin})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
         
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div>
<NavLink2 />
          <h1>Mutual Funds</h1>
        </div>
       
      </div>
      <div className="mutual-cards">
        <h4 className="header4">Mutual Funds</h4>

        <div className="mutual-card1">
          <div className="mutual-img-holder">
            {" "}
            <img src={Image18} alt="" className="mutual-img" />
          </div>
          <div className="mutual-info">
            <h4>TrustBanc Money Market Fund </h4>
            <p>
              TrustBanc Money Market Fund (“The Fund”) is an open-ended Fund
              authorized and registered as a Unit Trust Scheme by the Securities
              & Exchange Commission (SEC). The Fund seeks to provide safety,
              diversification and competitive return by investing in high
              quality short-term securities such as Treasury
              Bills, Commercial Papers and Fixed Deposits with an Investment
              Grade Rating. The Fund performance is benchmarked against the
              FGN 90 days Treasury Bill yield.
            </p>
            <p className="fw-bold">Other Benefits</p>
              <img src={Right} alt="" style={{ width: "20px" }} />
              Competitive return with low risk 
           <br/>
          
              <img src={Right} alt="" style={{ width: "20px" }} />Capital preservation
              <br/>
              {" "}
              <img src={Right} alt="" style={{ width: "20px" }} />
              Professionally Managed
              <br/>
           <img src={Right} alt="" style={{ width: "20px" }} />
           Free entry and exit
           <br/>
           <a href="https://www.trustbancasset.com/subscription form.pdf"
           target="_blank"
           rel="noreferrer"
           >
           <button  className="sub-form">Download subscription form</button>
           </a>
           
          </div>
        </div>
        <div className="service-grid mx-auto p-2  justify-content-center" >
          <div className="row mb-3" data-aos="zoom-in-up">
            {" "}
            <h3 style={{ fontSize: "20px", color: "black" }}>WE ALSO OFFER</h3>
            <div className="service-cards col-sm-12 col-lg-6">
              <h1 style={{}}>Wealth Management</h1>
              <div className="service-card1">
                <div className="service-img2">
                <img src={Image17} alt=""className="w-100 h-100 rounded-xl"/></div>
                <div className="service-info">
                  <p>
                    Our investment advisory service provides financial
                    management and wealth advisory services to a wide range of
                    clients...{" "}
                    <button className="service-btn">
                      <Link to="/wealthmanagement" className="service-link">
                        Learn more
                      </Link>{" "}
                      <img src={Maskgroup4} alt="" className="more-img" />
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div className="service-cards col-sm-12 col-lg-6">
            <h1 style={{}}>Portfolio Management</h1>
            <div className="service-card1">
              <div className="service-img2">
              <img src={Image19} alt="" className="w-100 h-100 rounded-xl" /></div>
              <div className="service-info">
                <p>
                Our Private Portfolio Product gives our clients a competitive
              advantage by providing them with tailor mage solutions and
              investments products that suits their finacial goals.
                  <button className="service-btn">
                    <Link to="/portfoliomanagement" className="service-link">
                      Learn more
                    </Link>{" "}
                    <img src={Maskgroup4} alt=""className="more-img" />
                  </button>
                </p>
              </div>
            </div>
          </div>
          </div>
          <div
            className="row"
            data-aos="zoom-in-up"
          >
            <div className="service-cards col-sm-12 col-lg-6">
              <h1 style={{}}>Research</h1>
              <div className="service-card1">
               <div className="service-img2">
                <img src={Image47} alt="" className=" w-100 h-100 rounded-xl" />
                </div>
                 <div className="service-info">
                  <p>
                    Research is our core tool to offer professional advice, we
                    rely on TrustBanc AM research findings to guide all our investment
                    actions on behalf of clients...{" "}
                    <button className="service-btn">
                      <Link to="/research" className="service-link">
                        Learn more
                      </Link>{" "}
                      <img src={Maskgroup4} alt="" className="more-img" />
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div className="service-cards col-sm-12 col-lg-6">
              <h1 style={{}}>Alt Invest (Ethical Investment)</h1>

              <div className="service-card1">
                <div  className="service-img2" >
                <img src={Image64} alt="" className=" w-100 h-100 rounded-xl" /></div>
                <div className="service-info">
                  <p>
                    A special investment product offered to investors that want
                    to earn halal returns on their investments. The product is
                    guided by Islamic Commercial jurisprudence.{" "}
                    <button className="service-btn">
                      <Link to="/ethicalinvestment" className="service-link">
                        Learn more
                      </Link>{" "}
                      <img src={Maskgroup4} alt="" className="more-img" />
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
<div>       	    
        <Footer5 />
      </div>
      <div className="footer-4">
        <Footer4 />
      </div>
    </div>
  );
}

export default MainMutualFundPage;
