import React from "react";

const Payment = ({ setShowDiv }) => {
  return (
    <div className="payment-card">
      <div className="payment-overlay">
        <p className="px-5">
          Click on the button to make a request for funds from any of your
          investments within the company TrustBanc Asset Management.
        </p>
        <button
          className="request-btn"
          onClick={() => {
            console.log("clicked");
            console.log(setShowDiv);
            setShowDiv(false);
          }}
        >
          {" "}
          Payment/Redemption
        </button>
      </div>
    </div>
  );
};

export default Payment;
